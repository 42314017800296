import storageService from "./storageService";
import * as sha1 from "js-sha1";

// import { Navigation } from 'react-native-navigation';

// import { Platform } from 'react-native';
import * as currencyFormatter from "currency-formatter";

const CommonService = {
  addToHomeButtonInstallPrompt: null,
  toast: {},
  airportServiceOptions: ["Hacia aeropuerto", "Desde aeropuerto"],
  windowHeight: 0,
  oAuthData: null,
  loginType: null,
  uploadPhoto: null,
  fromBack: false,
  referralCode: null,
  passengerDetailsOnward: null,
  passengerDetailsReturn: null,
  passengerContactDetails: null,
  prefIcons: {
    ciga: {
      icon: "/assets/carpool/cigerette-circle.png",
      name: "Fumador",
    },
    music: {
      icon: "/assets/carpool/music-circle.png",
      name: "Música",
    },
    only_girl: {
      icon: "/assets/carpool/girl-circle.png",
      name: "Solo mujeres",
    },
    ac: {
      icon: "/assets/carpool/ac-circle.png",
      name: "A / C",
    },
    luggage: {
      icon: "/assets/carpool/luggage-circle.png",
      name: "Equipaje",
    },
    conversation: {
      icon: "/assets/carpool/chat-circle.png",
      name: "Conversación",
    },
    front_seat: {
      icon: "/assets/carpool/seat-forward-circle.png",
      name: "Asiento\ndelantero",
    },
    baby_seat: {
      icon: "/assets/carpool/seat-babies-circle.png",
      name: "Fumador",
    },
    pets: {
      icon: "/assets/carpool/paw-circle.png",
      name: "Pet\nfriendly",
    },
  },
  prefIconsGrey: {
    ciga: {
      icon: "/assets/carpool/cigerette-grey-circle.png",
      name: "Fumador",
    },
    music: {
      icon: "/assets/carpool/music-grey-circle.png",
      name: "Música",
    },
    only_girl: {
      icon: "/assets/carpool/girl-grey-circle.png",
      name: "Solo mujeres",
    },
    ac: {
      icon: "/assets/carpool/ac-grey-circle.png",
      name: "A / C",
    },
    luggage: {
      icon: "/assets/carpool/luggage-grey-circle.png",
      name: "Equipaje",
    },
    conversation: {
      icon: "/assets/carpool/chat-grey-circle.png",
      name: "Conversación",
    },
    front_seat: {
      icon: "/assets/carpool/seat-forward-grey-circle.png",
      name: "Asiento\ndelantero",
    },
    baby_seat: {
      icon: "/assets/carpool/seat-babies-grey-circle.png",
      name: "Fumador",
    },
    pets: {
      icon: "/assets/carpool/paw-grey-circle.png",
      name: "Pet\nfriendly",
    },
  },
  formatData: (jsonArr) => {
    if (jsonArr) {
      let keys = jsonArr[0];
      let currArr = [];
      for (let i = 1; i < jsonArr.length; i++) {
        let currentObj = {};
        for (let j = 0; j < keys.length; j++) {
          currentObj[keys[j]] = jsonArr[i][j];
        }
        currArr.push(currentObj);
      }
      return currArr;
    }
  },

  appendAmPm: (time) => {
    return time.split(":")[0] >= 12 ? "PM" : "AM";
  },

  capitalize: (str) => {
    if (str) {
      let strArr = str.split(" ");
      for (let i = 0; i < strArr.length; i++) {
        if (strArr[i] && strArr[i][0]) {
          strArr[i] =
            strArr[i][0].toUpperCase() + strArr[i].substr(1).toLowerCase();
        }
      }
      return strArr.join(" ");
    }
    return "";
  },
  toLowerCase: (str) => {
    if (str) {
      return str.toLowerCase();
    }
  },
  round: (number, digit) => {
    let p = Math.pow(10, digit);
    return Math.round(number * p) / p;
  },
  setSeatLayout: (serviceDetails, route) => {
    try {
      let coachLayoutJson = [];
      let upperAvalibility;
      let originStageDetails;
      let destinationStageDetails;

      let errorMessage;
      if (serviceDetails.code === 416) {
        errorMessage = serviceDetails.message;
      } else {
        let seatDetails = serviceDetails.coach_details.seat_details;
        for (let seat of seatDetails) {
          if (!(CommonService.isTrain() || serviceDetails.travel_id == 272)) {
            if (typeof coachLayoutJson[seat.row_id] == "undefined") {
              coachLayoutJson[seat.row_id] = [];
            }
          } else {
            if (typeof coachLayoutJson[seat.floor_no] == "undefined") {
              coachLayoutJson[seat.floor_no] = [];
            }
            if (
              typeof coachLayoutJson[seat.floor_no][seat.row_id] == "undefined"
            ) {
              coachLayoutJson[seat.floor_no][seat.row_id] = [];
            }
          }
          let currentSeatJson = {};

          let berthTextPos = seat.type.indexOf("Berth");
          currentSeatJson.rowSpan = berthTextPos !== -1 ? 2 : 1;
          currentSeatJson.isBerth = berthTextPos !== -1 ? true : false;
          currentSeatJson.isReservable = seat.available;
          currentSeatJson.berthType = seat.berthType;
          currentSeatJson.isUpper = seat.floor_no ? seat.floor_no === 1 : false;
          if (currentSeatJson.isUpper) {
            upperAvalibility = currentSeatJson.isUpper;
          }
          currentSeatJson.isLower = !currentSeatJson.isUpper;
          currentSeatJson.object = seat;
          currentSeatJson.number = seat.number;
          currentSeatJson.isLadiesSeat = seat.is_ladies_seat;
          currentSeatJson.isGentsSeat = seat.is_gents_seat;
          currentSeatJson.type = seat.type;
          currentSeatJson.isGangWay = seat.type.indexOf("Gang") !== -1;
          currentSeatJson.isBreak = seat.type.indexOf("Break") !== -1;
          currentSeatJson.isSelected = false;
          if (!(CommonService.isTrain() || serviceDetails.travel_id == 272)) {
            coachLayoutJson[seat.row_id][seat.col_id] = currentSeatJson;
          } else {
            coachLayoutJson[seat.floor_no][seat.row_id][seat.col_id] =
              currentSeatJson;
          }
        }
        originStageDetails = serviceDetails.boarding_stages;
        destinationStageDetails = serviceDetails.dropoff_stages;
        let boardingStage =
          originStageDetails.length == 1 ? originStageDetails[0] : null;
        let droppingStage =
          destinationStageDetails.length == 1
            ? destinationStageDetails[0]
            : null;

        return {
          coachLayoutJson: coachLayoutJson,
          originStageDetails: originStageDetails,
          destinationStageDetails: destinationStageDetails,
          boardingStage: boardingStage,
          droppingStage: droppingStage,
          upperAvalibility: upperAvalibility,
          route: route,
        };
      }
    } catch (e) {
      console.log(
        "Exception in seat-laout.ts --> defaultCall() --> apiFactory.getServiceDetails(path)",
        e
      );
    }
  },

  splitRutAndDv: (rut) => {
    let cValue = rut.replace(/[\.\-]/g, "");
    if (cValue.length == 0) return [null, null];
    if (cValue.length == 1) return [cValue, null];
    let cDv = cValue.charAt(cValue.length - 1);
    let cRut = cValue.substring(0, cValue.length - 1);
    return [cRut, cDv];
  },

  formatRut: (value) => {
    let rutAndDv = value ? CommonService.splitRutAndDv(value) : "";
    let cRut = rutAndDv[0];
    let cDv = rutAndDv[1];
    if (!(cRut && cDv)) return cRut || value;
    let rutF = "";
    while (cRut.length > 3) {
      rutF = "." + cRut.substr(cRut.length - 3) + rutF;
      cRut = cRut.substring(0, cRut.length - 3);
    }
    return cRut + rutF + "-" + cDv;
  },

  isRutValid: (rut) => {
    if (typeof rut !== "string") return false;
    let cRut = rut.replace(/[\.\-]/g, "");
    if (cRut.length < 2) return false;
    let cDv = cRut.charAt(cRut.length - 1).toUpperCase();
    let nRut = parseInt(cRut.substr(0, cRut.length - 1));
    if (nRut === NaN) return false;
    return CommonService.computeDv(nRut).toString().toUpperCase() === cDv;
  },

  isRut: (type, idCardTypes) => {
    let cardType = idCardTypes.filter((t) => {
      return t.id == type;
    });
    if (
      cardType &&
      cardType[0] &&
      cardType[0].name &&
      cardType[0].name.toLowerCase().indexOf("rut") > -1
    ) {
      return true;
    }
    return false;
  },

  computeDv: (rut) => {
    let suma = 0;
    let mul = 2;
    if (typeof rut !== "number") return;
    rut = rut.toString();
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + rut.charAt(i) * mul;
      mul = (mul + 1) % 8 || 2;
    }
    switch (suma % 11) {
      case 1:
        return "k";
      case 0:
        return 0;
      default:
        return 11 - (suma % 11);
    }
  },

  copyObject: (ob) => {
    if (!ob) return {};
    return JSON.parse(JSON.stringify(ob));
  },

  isEMailValid: (email) => {
    if (email) {
      if (
        email.match(
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})+$/
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  storeLoginDetails: (loginData, loginSuccess) => {
    global.token = loginData ? loginData.auth_token : null;
    if (loginData) {
      if (loginData.carpool_customer) {
        global.xAccess = loginData.carpool_customer.access_token;
      }
      if (loginData.carpool_driver) {
        global.driverXAccess = loginData.carpool_driver.access_token;
      }
    } else {
      global.xAccess = null;
      global.driverXAccess = null;
    }
    loginSuccess(loginData);
    loginData
      ? localStorage.setItem("loginData", JSON.stringify(loginData))
      : localStorage.removeItem("loginData");
  },
  logout: (loginSuccess, googleLogout) => {
    loginSuccess(null);
    localStorage.removeItem("loginData");
    let googleAccessToken = localStorage.getItem("googleAccessToken");
    if (googleAccessToken && googleLogout) {
      googleLogout({ token: googleAccessToken });
      localStorage.removeItem("googleAccessToken");
    }
  },
  isLoggedIn: (loginData) => {
    if (!loginData || !Object.keys(loginData).length) {
      return false;
    }
    return true;
  },

  getStatusName: (status) => {
    switch (status) {
      case "ACCEPTED":
        return "Aceptado";
      case "CONFIRMED":
        return "Confirmado";
      case "STARTED":
        return "Iniciado";
      case "ENDED":
        return "Finalizado";
      case "CANCELLED":
        return "Cancelado";
      case "BOARDED":
        return "Abordado";
      case "REJECTED":
        return "Rechazado";
      case "PENDING_APPROVAL":
        return "Pendiente de aprobación";
      case "SCHEDULED":
        return "Agendado";
      case "NO_SHOW":
        return "No se presentó";
      case "DROPPED":
        return "Finalizado";
      case "EXPIRED":
        return "Expirado";
      default:
        return status;
    }
  },

  // goToScreen: (component,screenName,title,props = {},hideHomeButton = false,showLogoutIcon = false) => {
  //     Navigation.push(component, {
  //         component: {
  //             name: screenName,
  //             passProps: props,
  //             options: {
  //                 topBar: {
  //                     title: {
  //                         text: title,
  //                         fontFamily: Platform.OS === 'android' ? 'Carnas Regular' : 'Carnas-Regular',
  //                         alignment: 'center'
  //                     },
  //                     rightButtons: !hideHomeButton ? {
  //                       id: 'rightHomeButton',
  //                       icon: Platform.OS === 'android' ? require('../assets/icons/home-icon.png') : require('../assets/icons/home-icon-ios.png')
  //                     } : showLogoutIcon ? {
  //                       id: 'rightHomeButton',
  //                       icon: Platform.OS === 'android' ? require('../assets/icons/logout.png') : require('../assets/icons/logout-ios.png')
  //                     } : null
  //                 },
  //                 animations: {
  //                   pop: {
  //                       content: {
  //                           alpha: {
  //                               from: 1,
  //                               to: .1,
  //                               duration: 250,
  //                               startDelay: 0,
  //                               interpolation: 'accelerate',
  //                           },
  //                           y: {
  //                             from: 0,
  //                             to: 600,
  //                             duration: 250,
  //                             startDelay: 0,
  //                             interpolation: 'accelerate',
  //                           },
  //                       },
  //                   },
  //                   push: {
  //                       waitForRender: true,
  //                       content: {
  //                           alpha: {
  //                               from: .1,
  //                               to: 1,
  //                               duration: 250,
  //                               startDelay: 0,
  //                               interpolation: 'decelerate',
  //                           },
  //                           y: {
  //                             from: 600,
  //                             to: 0,
  //                             duration: 250,
  //                             startDelay: 0,
  //                             interpolation: 'decelerate',
  //                           },
  //                       },
  //                   },
  //                 }
  //             }
  //         }
  //     });
  // },

  //   goToScreenForScan: (component,screenName,title,props = {},hideHomeButton = false,showLogoutIcon = false) => {
  //     Navigation.push(component, {
  //         component: {
  //             name: screenName,
  //             passProps: props,
  //             options: {
  //                 topBar: {
  //                     title: {
  //                         text: title,
  //                         fontFamily: Platform.OS === 'android' ? 'Carnas Regular' : 'Carnas-Regular',
  //                         alignment: 'center'
  //                     },
  //                     rightButtons: !hideHomeButton ? {
  //                       id: 'rightHomeButton',
  //                       icon: Platform.OS === 'android' ? require('../assets/icons/home-icon.png') : require('../assets/icons/home-icon-ios.png')
  //                     } : showLogoutIcon ? {
  //                       id: 'rightHomeButton',
  //                       icon: Platform.OS === 'android' ? require('../assets/icons/logout.png') : require('../assets/icons/logout-ios.png')
  //                     } : null
  //                 },
  //             }
  //         }
  //     });
  // },

  // goToHome: (component) => {
  //   Navigation.popToRoot(component)
  // },

  // goBack: (component) => {
  //   Navigation.pop(component)
  // },

  // rightButonHome: (buttonId,component,returnDateSelected = null) => {
  //   if(buttonId == 'rightHomeButton'){
  //     if(returnDateSelected){
  //       returnDateSelected(null)
  //     }
  //     Navigation.popToRoot(component)
  //   }
  // },

  // bindNav: (ref) => {
  //   Navigation.events().bindComponent(ref);
  // },

  phoneNumberFormat: (text, len = 9) => {
    if (text) {
      text = (text + "").replace(/[a-zA-Z]/g, "");
      if ((text + "").length > len) {
        text = (text + "").substr(0, len);
      }
    }
    // if(text){
    //   text = (text+'').replace(/^\d{3}" "\d{9}$/, "")
    //   if((text+'').length > 12){
    //       text = (text+'').substr(0,12);
    //   }
    // }
    return text;
  },

  onlyNumbers: (text) => {
    if (text) {
      text = (text + "").replace(/[^0-9]/g, "");
    }
    return text;
  },

  onlyNumericValues: (text) => {
    if (text.match(/[^0-9]/g)) {
      return false;
    } else return true;
  },

  isEqual: (value, other) => {
    // Get the value type
    var type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    var valueLen =
      type === "[object Array]" ? value.length : Object.keys(value).length;
    var otherLen =
      type === "[object Array]" ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    // Compare two items
    var compare = function (item1, item2) {
      // Get the object type
      var itemType = Object.prototype.toString.call(item1);

      // If an object or array, compare recursively
      if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
        if (!CommonService.isEqual(item1, item2)) return false;
      }

      // Otherwise, do a simple comparison
      else {
        // If the two items are not the same type, return false
        if (itemType !== Object.prototype.toString.call(item2)) return false;

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (itemType === "[object Function]") {
          if (item1.toString() !== item2.toString()) return false;
        } else {
          if (item1 !== item2) return false;
        }
      }
    };

    // Compare properties
    if (type === "[object Array]") {
      for (var i = 0; i < valueLen; i++) {
        if (compare(value[i], other[i]) === false) return false;
      }
    } else {
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          if (compare(value[key], other[key]) === false) return false;
        }
      }
    }

    // If nothing failed, return true
    return true;
  },

  currency: (amount) => {
    let formattedAmount = currencyFormatter.format(amount, { code: "CLP" });
    return "$" + formattedAmount.replace("$", "").trim().split(",")[0];
  },

  generateXAccess: (key, token) => {
    let timeNow = Math.floor(new Date().getTime() / 1000);
    let t = [timeNow, sha1.hex([key, token, timeNow].join(""))].join(".");
    return t;
  },

  getCurrentTimeUTC: () => {
    var d = new Date();
    return +((d.getTime() + d.getTimezoneOffset() * 60000) / 1000).toFixed(0);
  },
  getTimeInSeconds: (date, type = "start") => {
    console.log("Timezone offset", date);

    // return (
    //   new Date(date).getTime() / 1000 - new Date().getTimezoneOffset() * 60
    // );

    var parts = date.trim().split("T");
    var date = parts[0].split("-");
    var time = (
      parts[1] ? parts[1] : type == "start" ? "00:00:00" : "23:59:59"
    ).split(":");

    // NOTE:: Month: 0 = January - 11 = December.
    var d = new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]);
    console.log("Date created is", type, d);
    return d.getTime() / 1000 + new Date().getTimezoneOffset() * 60;
  },

  isTrain: () => {
    return (
      window.location.pathname.indexOf("train") > -1 ||
      window.location.pathname.indexOf("tren") > -1
    );
  },

  isTrainOrBus: () => {
    return (
      window.location.pathname.indexOf("train") > -1 ||
      window.location.pathname.indexOf("tren") ||
      window.location.pathname.indexOf("bus") > -1
    );
  },
  isAtCancellation: () => {
    return window.location.pathname.indexOf("at-cancel-trip") > -1;
  },
  ceilNumber: (rnum, rlength = 0) => {
    var newnumber =
      Math.ceil(rnum * Math.pow(10, rlength)) / Math.pow(10, rlength);
    return newnumber;
  },
  getStringWithoutAccent: (str) => {
    if (str) {
      return str
        .toLowerCase()
        .replace("ñ", "n")
        .replace("á", "a")
        .replace("é", "e")
        .replace("ó", "o")
        .replace("í", "i")
        .replace("ú", "u");
    }
  },

  getParameterByName: (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  getThirdPartyApp: () => {
    return CommonService.getParameterByName("referal_app");
    // return window.location.href.indexOf('mstage.kupas.cl') > -1
  },
  idThirdPartyApp: () => {
    // return CommonService.getParameterByName('referal_app');
    // return true
    return (
      window.location.href.indexOf("muberstage.kupas.cl") > -1 ||
      window.location.href.indexOf("muber.kupos.cl") > -1
    );
  },
  setReferralCode: (code) => {
    CommonService.referralCode = code;
  },
  isPassportDoc: () => {
    console.log("Login Data is", this.props.data.loginData);
    return false;
  },
  getWindowHeight(pts = 100) {
    return (CommonService.windowHeight / 100) * pts;
  },

  clearCoachLayoutSelectedSeats: (serviceSelected, onClear) => {
    if (serviceSelected && serviceSelected.coachLayoutJson) {
      if (CommonService.isTrain()) {
        for (let wLayout of serviceSelected.coachLayoutJson) {
          for (let row of wLayout) {
            for (let seat of row) {
              seat.isSelected = false;
            }
          }
        }
      } else {
        for (let row of serviceSelected.coachLayoutJson) {
          for (let seat of row) {
            seat.isSelected = false;
          }
        }
      }
      onClear(serviceSelected);
    }
  },

  getPosition: (string, subString, index) => {
    return string ? string.split(subString, index).join(subString).length : -1;
  },

  normalizeAddress: (address) => {
    return address
      ? address.substr(0, CommonService.getPosition(address, ",", 2))
      : "";
  },
  removeExtraSpacesInString: (str) => {
    return str ? str.replace(/  +/g, " ") : "";
  },
  createImage: async (url) => {
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  },
  getRotatedImage: async (imageSrc, rotation = 0) => {
    const image = await CommonService.createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const orientationChanged =
      rotation === 90 ||
      rotation === -90 ||
      rotation === 270 ||
      rotation === -270;
    if (orientationChanged) {
      canvas.width = image.height;
      canvas.height = image.width;
    } else {
      canvas.width = image.width;
      canvas.height = image.height;
    }

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.drawImage(image, -image.width / 2, -image.height / 2);

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  },

  pushGoogleTagEvent: (event, data) => {
    (window.dataLayer || []).push({
      event: event,
      data,
    });
  },

  pushGoogleTagEventEnhanced: (event, data) => {
    (window.dataLayer || []).push({
      event: event,
      ...data,
    });
  },

  googleProductClick: (productObj) => {
    (window.dataLayer || []).push({
      event: "productClick",
      ecommerce: {
        click: {
          actionField: { list: "Search Results" }, // Optional list property.
          products: [
            {
              name: productObj.name, // Name or ID is required.
              id: productObj.id,
              price: productObj.price,
              brand: productObj.brand,
              category: productObj.category,
            },
          ],
        },
      },
    });
  },

  googleProductCheckout: (products, paymentOption, couponSelected, id) => {
    (window.dataLayer || []).push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: {
            option: paymentOption,
            coupon: couponSelected,
            id: id,
          },
          products: products,
        },
      },
    });
  },

  googleProductPurchase: (
    products,
    paymentOption,
    couponSelected,
    affiliation,
    id
  ) => {
    (window.dataLayer || []).push({
      ecommerce: {
        purchase: {
          actionField: {
            option: paymentOption,
            coupon: couponSelected,
            affiliation: affiliation,
            id: id,
          },
          products: products,
        },
      },
    });
  },
  googleRefund: (id, products) => {
    (window.dataLayer || []).push({
      ecommerce: {
        refund: {
          actionField: { id: id },
          products: products,
        },
      },
    });
  },

  convertBusServiceToGoogleProduct: (seat, service, cat) => {
    if (service) {
      return {
        name: service.bus_type, // Name or ID is required.
        id: service.pnr_number,
        price: service.total_booked_amount,
        // price: seat.price,
        brand: service.operator_service_name,
        category: cat,
      };
    }
  },
  encodeUrlAddress: (string) => {
    return string.toLowerCase()
      .replace(/, /g, ',')
      .split(' - ').join('_')
      .replace(/ /g, '-')
      .replace(/_/g, '--')
  },
  decodeUrlAddress: (string) => {
    return string.split(",")
      .join(", ")
      .split("--")
      .join("_")
      .split("-")
      .join(" ")
      .split("_")
      .join(" - ")
      .toLowerCase();
  }
};

export default CommonService;
