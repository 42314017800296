import React, { Component } from 'react';
// import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import PBText from './PBText';
import { CommonService } from '../services';
import PBTouchable from './PBTouchable';

export default class PBRadioGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioButtons: this.validate(this.props.radioButtons),
    };
  }

  validate = (data) => {
    let selected = false; // Variable to check if "selected: true" for more than one button.
    data.map(e => {
      e.color = this.props.color ? this.props.color : '#444';
      e.disabled = e.disabled ? e.disabled : false;
      e.label = e.label ? e.label : 'You forgot to give label';
      e.labelColor = e.labelColor ? e.labelColor : '#444';
      e.layout = e.layout ? e.layout : 'row';
      e.selected = e.selected ? e.selected : false;
      if (e.selected) {
        if (selected) {
          e.selected = false; // Making "selected: false", if "selected: true" is assigned for more than one button.
          console.log('Found "selected: true" for more than one button');
        } else {
          selected = true;
        }
      }
      e.size = this.props.size ? this.props.size : 24;
      e.value = e.value ? e.value : e.label;
    });
    if (!selected && data && data[0]) {
      data[0].selected = true;
    }
    return data;
  }

  onPress = label => {
    let radioButtons = this.state.radioButtons;
    let selectedIndex = radioButtons.findIndex(e => e.selected == true);
    let selectIndex = radioButtons.findIndex(e => e.label == label);
    if (selectedIndex != selectIndex) {
      radioButtons[selectedIndex].selected = false;
      radioButtons[selectIndex].selected = true;
      this.setState({ radioButtons });
      this.props.onPress(radioButtons[selectIndex]);
    }
  };

  render() {
    if(!CommonService.isEqual(this.validate(this.props.radioButtons),this.state.radioButtons)){
       this.setState({radioButtons:this.validate(this.props.radioButtons)})
    }
    return (
      <div className="radio-group-container" >
        <div className="display-flex"
        // style={{ flexDirection: this.props.flexDirection }}
        >
          {this.state.radioButtons.map(data => (
            <RadioButton key={data.label} data={data} onPress={this.onPress} />
          ))}
        </div>
      </div>
    );
  }
}

class RadioButton extends Component {
  render() {
    let data = JSON.parse(JSON.stringify(this.props.data));
    let opacity = data.disabled ? 0.2 : 1;
    let layout = { flexDirection: 'row' };
    let margin = { marginLeft: 10 };
    if (data.layout === 'column') {
      layout = { alignItems: 'center' };
      margin = { marginTop: 10 };
    }
    return (
      <div className="radion-button" style={{ opacity: opacity }}>
        <PBTouchable
          style={{ flex: 1 }}
          onPress={() => 
            data.disabled ? null : this.props.onPress(data.label)
          }
        >
          <div
            className="radio-button-input"
            // style={[layout,{width:'100%',paddingVertical: 5, }]}
          >
            <div
              className="outer-border-radio-button"
              // style={{
              //     borderColor: data.color,
              //     width: data.size,
              //     height: data.size,
              //     borderRadius: data.size / 2,
              //     alignSelf: 'center'
              //   }
              // ,!data.selected ? {borderColor:'#777'}: {}
              // }
              style={!data.selected ? { borderColor: "#777" } : {}}
            >
              {data.selected && (
                <div
                  className="inner-radio-button"
                  // style={{
                  //   backgroundColor: data.color,
                  //   width: data.size / 2,
                  //   height: data.size / 2,
                  //   borderRadius: data.size / 2
                  // }}
                />
              )}
            </div>
            <PBText
            // style={[{ alignSelf: 'center' }, margin, {color: data.labelColor}]}
            >
              {data.label}
            </PBText>
          </div>
        </PBTouchable>
      </div>
    );
  }
}

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  border: {
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
};