import React from 'react'
import { Link } from 'react-router-dom';
// import { Platform,TouchableNativeFeedback,TouchableOpacity } from 'react-native';

const PBTouchable = props => {
        return (
        !props.url ? <div className={props.className} onClick={ props.onClick || props.onPress} style={props.style}> 
                {props.children} 
            </div> 
            : <Link to={props.url} > {props.children}  </Link>   
        
        )
}


export default PBTouchable;