import React from 'react'

const Hr = props => {
    return (
      <div
        className={"line" + " " + props.color + " " + props.borderHeight}
        // style={[styles.line,{borderBottomWidth:(props.thicknes?props.thicknes:1),borderBottomColor:(props.color?props.color:'#ccc')},props.style]}
      ></div>
    );
}

export default Hr;