import React, { PureComponent } from 'react'
// import {commonStyles, dimensions} from '../../theme';
import PBText from '../PBText';
import PBTouchable from '../PBTouchable';


class SortStrip extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scrollPosition: 0
    }
  }

  changeScrollPosion = (type) => {
    this.setState({ scrollPosition: (type == 2 ? this.state.scrollPosition + 1 : this.state.scrollPosition - 1) })
  }

  render() {
    const { props } = this;
    const operator = JSON.parse(localStorage.getItem('op'))
    return (
      <div
        className={"sort-strip-container flex-row light-text font12 " + (this.state.scrollPosition > 0 && 'padding-left ') + (this.state.scrollPosition < 4 && ' padding-right')}
        // style={styles.sortStripContainer}
        style={{ backgroundColor: operator && operator.theme_color2 ? operator.theme_color2 : '' }}

      >
        {
          this.state.scrollPosition > 0 ? (
            <div
              className="sort-scroll-arrow left"
              // styles={styles.dateArrow}
              onClick={() => this.changeScrollPosion(1)}
              // style={{ backgroundColor: operator && operator.theme_color2 ? operator.theme_color2 : '' }}
              style={{ backgroundColor: operator && operator.theme_color2 ? operator.theme_color2 : '' }}

            >
              <img
                src="../assets/svgs/left-arrow-white.svg"
              />
            </div>
          ) : null
        }
        <div className={"flex-row scrollitem "} style={{ marginLeft: '-' + this.state.scrollPosition * 33 + '%' }}

        >
          {
            props.capsuleMenuIcons.map((val, key) => key < 3 ? (
              <div key={key} className={"view-flex sort-item " + (props.activeTab == key ? 'active bold-text' : ' ')}
              >
                <div
                  className="sort-item-inner"
                  onClick={() => props.onSort(key)}
                // style={styles.sortItemInner}
                >
                  <div className={(props.activeTab == key && val.iconActive) ? "whitebg" : ""}><img src={(props.activeTab == key && val.iconActive) ? val.iconActive : val.icon} /></div>
                  <span className="sort-item-name" >
                    {val.label}
                  </span>
                  <SortIcon
                    sortType={props.sortType}
                    showIcon={props.sortFeild == val.sortFeild}
                  />
                </div>
              </div>
            ) : null)
          }
        </div>
        <div

          className={"flex-row scrollitem " + (this.state.scrollPosition > 0 && 'padding-left ') + (this.state.scrollPosition < 2 && 'padding-right')}>
          {
            props.capsuleMenuIcons.map((val, key) => key > 2 ? (
              <div key={key} className={"view-flex sort-item " + (props.activeTab == key ? 'active bold-text' : ' ')}>
                <div
                  className="sort-item-inner"
                  onClick={() => props.onSort(key)}
                // style={styles.sortItemInner}
                >
                  <div className={(props.activeTab == key && val.iconActive) ? "whitebg" : ""}><img src={(props.activeTab == key && val.iconActive) ? val.iconActive : val.icon} /></div>
                  <span className="sort-item-name" >
                    {val.label}
                  </span>
                  <SortIcon
                    sortType={props.sortType}
                    showIcon={props.sortFeild == val.sortFeild}
                  />
                </div>
              </div>
            ) : null)
          }
        </div>


        {
          this.state.scrollPosition < 2 ? (
            <div
              className="sort-scroll-arrow right"
              // styles={styles.dateArrow}
              onClick={() => this.changeScrollPosion(2)}
              style={{ backgroundColor: operator && operator.theme_color2 ? operator.theme_color2 : '' }}

            >
              <img
                src="../assets/svgs/right-arrow-white.svg"
              />
            </div>
          ) : null
        }

        {/* <div className="view-flex sort-item">
          <PBTouchable onPress={() => props.onSort("dep_time")}>
            <div
              className="sort-item-inner"
              // style={styles.sortItemInner}
            >
              <PBText fontColor={"white-font"} fontSize={"font10"}>
                SALIDA
              </PBText>
              <SortIcon
                sortType={props.sortType}
                showIcon={props.sortFeild == "dep_time"}
              />
            </div>
          </PBTouchable>
        </div>
        <div className="view-flex sort-item-last">
          <PBTouchable
            className="sort-strip-filter-item"
            onPress={() => props.onSort("lowestFarePrice")}
          >
            <div
              className="sort-item-inner"
              // style={styles.sortItemInner}
            >
              <PBText fontColor={"white-font"} fontSize={"font10"}>
                PRECIOS
              </PBText>
              <SortIcon
                sortType={props.sortType}
                showIcon={props.sortFeild == "lowestFarePrice"}
              />
            </div>
          </PBTouchable>
        </div> */}
        {/* <div style={{ ...styles.empty }}></div> */}
      </div>
    );
  }
}

const SortIcon = props => {
  return props.showIcon ? (
    <img
      className={"sort-strip-img " + (props.sortType == "ASC" ? 'asc' : 'desc')}
      src={"../assets/icons/service-sort/sort-arrow.png"}
    />
  ) : null;
}


const styles = {
  sortStripContainer: {
    backgroundColor: '$primaryBG',
    flexDirection: 'row',
    width: '100%',
    paddingBottom: 3,
    paddingTop: 3
  },
  sortItemInner: {
    width: 100,
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sortItem: {
    borderRightWidth: 1,
    borderRightColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sortText: {
    fontSize: 12,
    marginRight: 4
  },
  viewFlex: {
    width: 100,
    alignItems: 'center',
  },
}


export default SortStrip;