import React from 'react'

const PBCard = props => {
    return (
      <div
        className={"kupos-card " + props.className}
        style={{padding:props.padding, marginTop:props.marginTop, position:props.position}}
        // style={{ padding: props.padding || props.padding === 0 ? props.padding : 10,marginBottom: props.marginBottom ? props.marginBottom : 0 }}
      >
        {props.children}
      </div>
      // <div style={[{...styles.container,padding:(props.padding || props.padding === 0)?props.padding:10,marginBottom:(props.marginBottom ? props.marginBottom : 0)},props.style]}>
      //     {props.children}
      // </div>
    );
}

export default PBCard;