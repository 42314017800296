import React from 'react'
import ModalDropdown from './ModalDropdown';
// import Image from 'react-native-scalable-image';
import { colors, dimensions } from '../theme';
import PBTouchable from './PBTouchable';
import PBInput from './PBInput';
import { IonIcon } from '@ionic/react';
import IonicDropdown from './IonicDropdown';

const PBSelect = props => {
  const { data, selectedText, selectedValue, icon, onChange, valKey, placeholder } = props;

  let displayRow = (rowData, rowID, highlighted) => {
    return (
      <div className="list-item" >
        <span className="list-item-text"
          style={{
            ...styles.listItemText,
            color: highlighted ? colors.primaryBG : "#444"
          }}
        >
          {rowData[valKey || "name"]}
        </span>
      </div>
    );
  }
  { console.log("data for dropdown", data) }
  return (
    <div style={styles.inputContainer} className="pb-select">
      {props.label ? (
        <div className="dark-font font14 bold-text">
          <span>{props.label}</span>
        </div>
      ) : null}

      <IonicDropdown
        data={data}
        dropdownClass={"ion-dropdown"}
        selectClass={"select-dropdown"}
        placeholder={placeholder}
        value={selectedValue}
        onSelectChange={value => onChange(value)}
        // onSelectChange={val => this.countryChanged(val, seat)}
      />
      {props.error ? (
        <div style={styles.errorTextContainer}>
          <span style={styles.errorText}>{props.errorMessage}</span>
        </div>
      ) : null}
    </div>
  );
}

const styles = {
  inputContainer: {
    paddingVertical: 5,
  },
  buttonInnerContainer: {
    height: 36,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5
  },

  selectedItemTextContainer: {
    flex: 1,
    paddingHorizontal: 10
  },
  dropDownButtonText: {
    color: '$textColor',
    fontSize: 14,
    fontFamily: 'Carnas Regular'
  },
  iconContainer: {
    height: '100%',
    alignItems: 'center',
    width: 50
  },
  listItem: {
    width: dimensions.vw * 84,
    flexDirection: 'row',
    padding: 10
  },
  listItemText: {
    marginLeft: 10,
    fontSize: 14,
    fontFamily: 'Carnas Regular'
  },
  arrow: {
    marginRight: 10
  },
  errorTextContainer: {
    paddingHorizontal: 2
  },
  errorText: {
    color: '#f00',
    fontSize: 11,
    fontFamily: 'Carnas Regular'
  },
  labelTextContainer: {
    paddingHorizontal: 2
  },
  labelText: {
    color: '$textColor',
    fontSize: 14,
    fontFamily: 'Carnas Regular'
  }
}


export default PBSelect;