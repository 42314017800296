// import {AsyncStorage} from 'react-native';


const storageService = {
    setItem : (key,value) => {
        try {
             localStorage.setItem(key, value);
        } catch (e) {
            // saving error
        }
    },
    getItem : (key) => {
        try {
          const value =  localStorage.getItem(key)
          if(value) {
            // value previously stored
            return value
          }
          return value;
        } catch(e) {
          // error reading value
        }
      },
      removeItem : (key) => {
          try {
            const value =  localStorage.removeItem(key)
            if(value) {
              // value previously stored
              return value
            }
            return value;
          } catch(e) {
            // error reading value
          }
        }
}



export default storageService;