import React from "react";

import { dimensions } from "../../theme";
import { CommonService } from "../../services";

const SelectedSeats = (props) => {
  const operator = JSON.parse(localStorage.getItem('op'))
  console.log(props)
  let seatTypes = () => {
    if (props.bookingDetails.selectedSeatTypes) {
      let i = 0;
      let seatTypes = Object.keys(props.bookingDetails.selectedSeatTypes).map(
        (type, j) => {
          i++;
          return (
            <div
              key={j}
              className={i <= 1 ? "null" : "seat-type-number-item"}
            // className="seat-type-number-item"
            // style={{ borderLeftWidth: i <= 1 ? 0 : 1 }}
            >
              <span
                className="seat-type-number-text"
              // style={styles.seatTypeNumberText}
              >
                {CommonService.capitalize(type)}{" "}
                {props.bookingDetails.selectedSeatTypes[type][0].seatNumbers}
              </span>
            </div>
          );
        }
      );
      return (
        <span
          className="fare-details-text"
        // style={styles.fareDetailsText}
        >
          {
            CommonService.currency(props.bookingDetails.netBookingAmount).split(
              ","
            )[0]
          }
        </span>
        // <div
        //   className="seat-types-fare"
        //   // style={styles.seatTypesFare}
        // >
        //   {/* <div
        //     className="seat-type-numbers"
        //     // style={styles.seatTypeNumbers}
        //   >
        //     {seatTypes}
        //   </div> */}
        //   <div style={styles.fareDetails}>
        //     <span className="fare-details-text"
        //     // style={styles.fareDetailsText}
        //     >
        //        ${props.bookingDetails.netBookingAmount}
        //     </span>
        //   </div>
        // </div>
      );
    }
  };
  let getSelectSeatFareDetails = () => {
    if (
      props.bookingDetails &&
      props.bookingDetails.selectedSeatsToBook &&
      props.bookingDetails.selectedSeatsToBook.length
    ) {
      return (
        // <div
        //   className="selected-seats-container-inner"
        //   // style={styles.selectedSeatsContainerInner}
        // >
        seatTypes()
        //</div>
      );
    }
    return null;
  };
  return props.bookingDetails &&
    props.bookingDetails.selectedSeatsToBook &&
    props.bookingDetails.selectedSeatsToBook.length ? (
    <button
      className="pb-button-else button-seat-selected"
      onClick={props.onPress}
      style={{ backgroundColor: operator && operator.button_color ? operator.button_color : '' }}

    >
      <span className="pb-button-text font16">
        CONTINUAR: {getSelectSeatFareDetails()}
      </span>
    </button>
  ) : (
    // <div
    //   className="selected-seats-container"
    //   // style={styles.selectedSeatsContainer}
    // >
    //   {getSelectSeatFareDetails()}
    // </div>

    // <div
    //   className="emptydiv"
    //   // style={styles.emptydiv}
    // ></div>
    <button
      className="pb-button-else-else button-seat-selected"
      onClick={props.onPress}

    >
      <span className="pb-button-text font17">
        CONTINUAR {getSelectSeatFareDetails()}
      </span>
    </button>
  );
};

const styles = {
  //selected seats
  emptydiv: {
    height: 20,
    padding: 6,
    paddingHorizontal: 10,
    opacity: 0.5,
  },
  selectedSeatsContainer: {
    height: dimensions.vh * 10,
    padding: 6,
    paddingHorizontal: 10,
    backgroundColor: "#fff",
  },
  selectedSeatsContainerInner: {
    borderWidth: 1,
    borderColor: "$primaryBG",
    borderRadius: 8,
    width: dimensions.vw * 92,
    flex: 1,
  },
  seatTypesFare: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  seatTypeNumbers: {
    flexDirection: "row",
  },
  seatTypeNumberItem: {
    borderLeftWidth: 1,
    borderLeftColor: "#ccc",
    paddingHorizontal: 5,
  },
  seatTypeNumberText: {
    fontSize: 13,
    fontFamily: "Carnas Regular",
  },
  fareDetailsText: {
    color: "$primaryBG",
    fontSize: 14,
    fontFamily: "Carnas Regular",
  },
};

export default SelectedSeats;
