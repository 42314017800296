import React from 'react'
// import { View, StyleSheet, Image } from 'react-native';
import { dimensions } from '../theme';
import getIcons from '../themes/functions/getIcons';

const PBCarpoolBG = props => {
  const operator = JSON.parse(localStorage.getItem('op'))
  return props.short ? (
    <img
      src={"/assets/carpool/bgs/home_short.png"}
      className="main-bg-short"
    />
  ) : (
    <img src={operator ? getIcons('BG') : "/assets/carpool/bgs/main.webp"} className="mainBgImg" />
  );
}

// const styles = {
//     mainBgImg:{
//         width:dimensions.vw * 100,height:dimensions.vh*30,resizeMode:'stretch',position:'absolute',
//     }
// }


export default PBCarpoolBG;
