import React from "react";
// import { View, Text, Platform } from 'react-native';
// import EStyleSheet from 'react-native-extended-stylesheet';

const PBText = (props) => {
  let text = props.children;
  let array = text ? text.toString().split("\n") : [""];
  return (
    <div
      className={
        (props.textAlign ? props.textAlign : "") +
        " " +
        (props.fontSize ? props.fontSize : "") +
        " " +
        (props.fontStyle ? props.fontStyle : "") +
        " " +
        (props.fontColor ? props.fontColor : "") +
        " " +
        (props.bold ? "bold-text" : "") +
        " " +
        (props.black ? "black-text" : "") +
        " " +
        (props.primary ? "primary-font" : "") +
        " " +
        (props.secondary ? "secondary-font" : "") +
        " " +
        (props.center ? "center-text" : "") +
        " " +
        props.className
      }
      style={props.style}
    >
      {text}
    </div>
  );
};

const styles = {};

export default PBText;
