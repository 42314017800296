import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class KuposCheckbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
    circular: PropTypes.bool,
    t: PropTypes.func
  };

  render() {
    console.log('radio', this.props)
    return (
      <div className="common-kupos-checkbox">
        <label onClick={this.props.onChange}>
          <style>
            .icon-check-square-pink, .icon-check-square-pink-line{'{'}
            background: url({this.props.operator && this.props.operator.radio_check_icons ? this.props.operator.radio_check_icons : '/assets/icons/check-sprite.png'}) no-repeat;
            overflow: hidden;
            text-indent: -9999px;
            text-align: left;
            background-size: 200% 200%;
            position: relative;
            top: 2px;
            width: 18px;
            height: 18px;
            {'}'}

            .icon-check-square-pink{'{'}
            background-position: -0% 100%
            {'}'}

            .icon-check-square-pink-line{'{'}
            background-position: 100% 100%;
            {'}'}
          </style>
          <div className={'kupos-checkbox '}>
            {/* <input type="checkbox" /> */}
            {this.props.circular ? (
              this.props.checked ? (
                <div className="icon-selection-circle-selected"></div>
              ) : (
                <div className="icon-selection-circle-not-selected"></div>
              )
            ) : this.props.checked ? (
              <div className="icon-check-square-pink"></div>
            ) : (
              <div className="icon-check-square-pink-line"></div>
            )}
          </div>
          <span className="checkbox-label">
            {this.props.t ? this.props.t('PROFILE.' + this.props.label) : this.props.label}
          </span>
        </label>
      </div>
    );
  }
}
