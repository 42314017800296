import React from 'react'
import { Route } from 'react-router-dom';

const TepualTermsAndConditionsScreen = React.lazy(() => import("../theme5/TepualPages/TepualTermsAndConditionsScreen"));
const TepualAboutUs = React.lazy(() => import("../theme5/TepualPages/TepualAboutUs"));
const TepualOffices = React.lazy(() => import("../theme5/TepualPages/TepualOffices"));
const TepualGallery = React.lazy(() => import("../theme5/TepualPages/TepualGallery"));
const TepualDestinationScreen = React.lazy(() => import("../theme5/TepualPages/TepualDestinationScreen"));
const TepualContactUs = React.lazy(() => import("../theme5/TepualPages/TepualContactUs"));


const tepualRoutes = [
    <Route exact path="/destinos" component={TepualDestinationScreen} />,
    <Route exact path="/about-us" component={TepualAboutUs} />,
    <Route exact path="/terms" component={TepualTermsAndConditionsScreen} />,
    <Route exact path="/office" component={TepualOffices} />,
    <Route exact path="/gallery" component={TepualGallery} />,
    <Route exact path="/contact" component={TepualContactUs} />
]


export default tepualRoutes
