import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { menuController } from '@ionic/core';
import React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { AppPage } from '../declarations';
import { PBText } from '../components';
import { connectData } from '../redux';
import {
  CommonService,
  storageService,
  AppData,
  GlobalService,
} from '../services';
import PBContactModal from './PBContactModal';

var global: any = window;

const WelcomeContainer = () => {
  return (
    <div className='welcome-container font10'>
      {/* // CommonService.isLoggedIn(props.loginData)? */}
      <div className='welcome-image-container'>
        {/* <img
          className="welcome-image-logged-in"
          src={"../assets/carpool/camera-add-circle.png"}
        /> */}
        <div className='camera-add-circle home-icon-circular-sprite size55'></div>
        <div className='user-name-container'>
          <div className='display-flex'>
            <PBText
              fontColor={'dark-font'}
              fontStyle={'bold-font'}
              fontSize={'font19'}
            >
              ¡Hola
            </PBText>
            <PBText
              fontColor={'dark-font'}
              fontStyle={'bold-font'}
              fontSize={'font16'}
            >
              !
            </PBText>
          </div>

          {/* {this.props.data.loginData ? */}
          <div className='display-flex'>
            <PBText fontSize={'font14'}>Modifica tu perfil</PBText>
            <img
              height={16}
              width={16}
              style={{ marginLeft: 6 }}
              src={'../assets/carpool/sidemenu/pencil.png'}
            />
          </div>
          {/* :null} */}
        </div>
      </div>

      {/* { 
                CommonService.isLoggedIn(props.loginData) 
                ?<View style={styles.welcomeTextContainer}><PBText style={styles.welcomeText}>{props.welcomeText}</PBText></View>
                :null
            } */}
    </div>
  );
};

interface MenuProps extends RouteComponentProps {
  appPages: AppPage[];
  setCitiesUnique: Function;
  setCitiesUniqueTrain: Function;
  loginSuccess: Function;
  getMetaDataSuccess: Function;
  getCitiesUnique: Function;
  getCitiesUniqueTrain: Function;
  getMetaData: Function;
  getOperatorSite: Function;
  data: any;
  showHideContactModal: any;
  setThirdPartyApp: Function;
  getATTerminals: Function;
  setATTerminals: Function;
  getSeatTypes: Function;
  setSeatTypes: Function;
  getATBlockTime: Function;
  setATBlockTime: Function;
  getSideMenuLinks: Function;
  setOperatorSite: Function;
}

class Menu extends React.Component<MenuProps> {
  state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      showContactModal: false,
    };
    let referalApp = CommonService.idThirdPartyApp();
    if (referalApp) {
      this.props.setThirdPartyApp(referalApp);
    } else {
      if (window.location.href.indexOf('kupas.cl') <= -1) {
        // window.location.href = "https://www.pasajebus.com"
      }
    }
  }

  componentDidMount() {
    CommonService.windowHeight = window.innerHeight;
    this.checkLogin();
  }

  checkLogin = () => {
    let citiesUnique = localStorage.getItem('citiesUnique');
    // let citiesUniqueTrain = localStorage.getItem("citiesUnique");
    if (citiesUnique) {
      let citiesUniqueJ = JSON.parse(citiesUnique);
      this.props.setCitiesUnique(citiesUniqueJ);
    }
    let citiesUniqueTrain = localStorage.getItem('citiesUniqueTrain');
    if (citiesUniqueTrain) {
      let citiesUniqueTrainJ = JSON.parse(citiesUniqueTrain);
      this.props.setCitiesUniqueTrain(citiesUniqueTrainJ);
    }

    let metaData = storageService.getItem('metaData');
    if (metaData) {
      this.props.getMetaDataSuccess(JSON.parse(metaData));
    }

    let loginData = storageService.getItem('loginData');
    if (loginData && Object.keys(loginData).length) {
      let loginJson = JSON.parse(loginData);
      // this.props.loginSuccess(loginJson);
      // // GlobalService.setGlobal('token',loginData.auth_token);
      global.token = loginJson.auth_token;
      CommonService.storeLoginDetails(loginJson, this.props.loginSuccess);
    } else {
      // GlobalService.setGlobal('token',null);
      global.token = null;
    }
    const {
      getMetaData,
      getOperatorSite,
      getCitiesUnique,
      getCitiesUniqueTrain,
      getATTerminals,
      getSeatTypes,
      getATBlockTime,
    } = this.props;
    getMetaData({
      callback: () => {
        setTimeout(() => {
          localStorage.setItem(
            'metaData',
            JSON.stringify(this.props.data.metaData)
          );
          if (this.props.data.metaData && this.props.data.metaData.carpool) {
            AppData.CARPOOL_URL = this.props.data.metaData.carpool.url;
            // GlobalService.setGlobal(
            //   "xkey",
            //   this.props.data.metaData.carpool.key
            // );
            global.xkey = this.props.data.metaData.carpool.key;
            // GlobalService.setGlobal(
            //   "xtoken",
            //   this.props.data.metaData.carpool.token
            // );
            global.xtoken = this.props.data.metaData.carpool.token;
          } else {
            AppData.CARPOOL_URL = 'http://34.235.163.236';
            // global.xkey = "kgim1416737390";
            GlobalService.setGlobal('xkey', 'kgim1416737390');
            // global.xtoken =
            // "7813126af6eedc003da23625e80c2b35082571d3b7b710e858d4a95163c828f5";
            GlobalService.setGlobal(
              'xtoken',
              '7813126af6eedc003da23625e80c2b35082571d3b7b710e858d4a95163c828f5'
            );
          }
        }, 400);
      },
      metaData: metaData,
    });

    getOperatorSite({
      callback: (res: any) => {
        console.log('res-----------', res);
        if (res && res.success && res.data.data && res.data.data.operator) {
          this.props.setOperatorSite(res.data.data.operator);
          localStorage.setItem('op', JSON.stringify(res.data.data.operator));

          getCitiesUnique({
            callback: (result: any) => {
              this.props.setCitiesUnique(result.cities);
              // console.log("result.cities", result.cities);
              setTimeout(() => {
                localStorage.setItem(
                  'citiesUnique',
                  JSON.stringify(result.cities)
                );
              }, 300);
            },
            cities: citiesUnique,
            operator_id: res.data.data.operator.operator_id,
          });
        } else {
          localStorage.removeItem('op');
          // localStorage.setItem("op", JSON.stringify(null));
        }
      },
    });

    // getCitiesUniqueTrain({
    //   callback: (result: any) => {
    //     this.props.setCitiesUniqueTrain(result.cities);
    //     setTimeout(() => {
    //       storageService.setItem(
    //         "citiesUniqueTrain",
    //         JSON.stringify(result.cities)
    //       );
    //     }, 300);
    //   },
    //   cities: citiesUniqueTrain,
    // });

    // getATTerminals({
    //   callback: (result: any) => {
    //     // console.log("Result from AT terminals is----", result)
    //     if (result && result.data && result.data.success && result.data.data)
    //       this.props.setATTerminals(result.data.data)
    //   }
    // })

    // getSeatTypes({
    //   callback: (result: any) => {
    //     if (result && result.data && result.data.success && result.data.data)
    //       this.props.setSeatTypes(result.data.data)
    //   }
    // })

    // getATBlockTime({
    //   callback: (result: any) => {
    //     if (result && result.data && result.data.success && result.data.data)
    //       this.props.setATBlockTime(result.data.data)
    //   }
    // })
  };

  editProfile = () => {
    // this.props.history.push("/modify-profile");
    // menuController.close();
  };
  goToLogin = () => {
    // this.props.history.push("/login-options");
    // menuController.close();
  };

  render() {
    const { appPages, showHideContactModal, data } = this.props;
    console.log('Menu Items', this.props);

    return data && data.operator && data.operator.theme_type == '1' ? null : (
      <IonMenu
        disabled={this.props.data.thirdPartyApp}
        contentId='main'
        type='overlay'
        className='split-menu-custom'
      >
        <IonContent>
          <div className='menu-container-div'>
            <div className='welcome-container font10' style={{ marginTop: 20 }}>
              {/* {CommonService.isLoggedIn(this.props.data.loginData) ? (
                <div className="welcome-image-container">
                  {this.props.data.loginData.image_link ? <div className="welcome-image-circle" style={{ backgroundImage: `url(${this.props.data.loginData.image_link || '/assets/carpool/camera-add-circle.png'})` }}>

                  </div> : <div className="camera-add-circle home-icon-circular-sprite size55"></div>}
                  <div className="user-name-container">
                    <div className="display-flex">
                      <PBText
                        fontColor={"dark-font"}
                        fontStyle={"bold-font"}
                        fontSize={"font16"}
                      >
                        ¡Hola&nbsp;
                      </PBText>
                      <PBText dark={true} bold={true} fontSize={"font16"}>
                        {this.props.data.loginData.name}!
                      </PBText>
                    </div>

                    {this.props.data.loginData ? (
                      <div className="display-flex" onClick={this.editProfile}>
                        <PBText fontSize={"font13"}>Modifica tu perfil</PBText>
                        <img
                          height={16}
                          width={16}
                          style={{ marginLeft: 6 }}
                          src={"../assets/carpool/sidemenu/pencil.png"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="welcome-image-container">
                  <div onClick={this.goToLogin} className="camera-add-circle home-icon-circular-sprite size55"></div>
                  <div className="user-name-container">
                    <div className="display-flex">
                      <PBText
                        fontColor={"dark-font"}
                        fontStyle={"bold-font"}
                        fontSize={"font16"}
                      >
                        ¡Hola!
                      </PBText>
                    </div>
                  </div>
                </div>
              )} */}

              {/* { 
                CommonService.isLoggedIn(props.loginData) 
                ?<View style={styles.welcomeTextContainer}><PBText style={styles.welcomeText}>{props.welcomeText}</PBText></View>
                :null
            } */}
            </div>

            {/* <div>
                {
                  <div className="welcome-image-container">
                    <img className="welcome-image" height={100} src={'../assets/images/pb_logo_circle.png'} />
                  </div>
                }
              </div> 
            */}

            <div
              className='pink-box'
              style={{
                backgroundColor:
                  this.props.data.operator &&
                  this.props.data.operator.button_color,
              }}
            ></div>
            <IonList>
              {appPages.map((appPage: any, index) => {
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    {/* {appPage.id == 4 ?
                      <IonItem
                        lines="none"
                        className="side-menu-item"
                        routerLink={appPage.url}
                        routerDirection="none"
                      >
                        {/* <div className={"home-icon-sprite " + appPage.icon}></div> */}
                    {/* <IonLabel className="side-menu-label font15">
                          {appPage.title}
                        </IonLabel>
                      </IonItem> : null */}
                    {/* // } */}

                    {/* {appPage.id == 9 ? */}
                    <IonItem
                      lines='none'
                      className='side-menu-item'
                      routerLink={appPage.url}
                      routerDirection='none'
                      // onClick={() => {
                      //   this.props.data.operator ?
                      //     window.open(`${ this.props.data.operator.domain_url }#contact`, '_parent') :
                      //     showHideContactModal(true)
                      // }}
                    >
                      {/* <div className={"home-icon-sprite " + appPage.icon}></div> */}
                      <IonLabel className='side-menu-label font16'>
                        {appPage.label}
                      </IonLabel>
                    </IonItem>
                    {/* : null */}
                    {/* } */}
                    {/* {appPage.id != 9 ? (
                      (appPage.id == 1 ||
                        appPage.id == 13 ||
                        appPage.id == 5 ||
                        appPage.id == 2 ||
                        appPage.id == 12) &&
                        !this.props.data.loginData ? (
                        <IonItem
                          lines="none"
                          className="side-menu-item"
                          routerLink="/login-options"
                          routerDirection="none"
                        > */}
                    {/* <img src={appPage.src} alt="" /> */}
                    {/* <div className={"home-icon-sprite " + appPage.icon}></div>
                          <IonLabel className="side-menu-label font15">
                            {appPage.title}
                          </IonLabel>
                        </IonItem>
                      ) : appPage.id != 1 ||
                        !this.props.data.loginData ||
                        this.props.data.loginData.identity_type == 7 ? (
                        <IonItem
                          lines="none"
                          className="side-menu-item"
                          routerLink={appPage.url}
                          routerDirection="none"
                        > */}
                    {/* <img src={appPage.src} alt="" /> */}
                    {/* <div className={"home-icon-sprite " + appPage.icon}></div>
                          <IonLabel className="side-menu-label font15">
                            {appPage.title}
                          </IonLabel>
                        </IonItem>
                      ) : null
                    ) : appPage.id == 9 ? (
                      <IonItem
                        lines="none"
                        className="side-menu-item"
                        onClick={() => showHideContactModal(true)}
                      > */}
                    {/* <img src={appPage.src} alt="" /> */}
                    {/* <div className={"home-icon-sprite " + appPage.icon}></div>
                        <IonLabel className="side-menu-label font15">
                          {appPage.title}
                        </IonLabel>
                      </IonItem>
                    ) : null} */}
                  </IonMenuToggle>
                );
              })}
            </IonList>
          </div>
        </IonContent>
      </IonMenu>
    );
  }
}

export default connectData()(withRouter(Menu));
