import React from "react";
import StageSelectBox from "./StageSelectBox";

const StagesSelection = (props) => {
  const {
    boardingStages,
    droppingStages,
    selectedBoardingStage,
    selectedDroppingStage,
    onStageChange,
  } = props;
  // console.log("Boarding stages ,,,,", boardingStages);
  return (
    <div style={styles.stageSelectionContainer} className="font14 relative">
      <StageSelectBox
        selectedItem={selectedBoardingStage}
        data={boardingStages}
        onChange={(stage) => onStageChange(1, stage)}
        icon='Origin'

        selectText="Seleccione punto de embarque"
      />
      <StageSelectBox
        selectedItem={selectedDroppingStage}
        data={droppingStages}
        onChange={(stage) => onStageChange(2, stage)}
        icon='Destination'
        selectText="Seleccione punto de llegada"
      />
    </div>
  );
};

const styles = {
  //stages
  stageSelectionContainer: {
    width: "100%",
  },
  stageSelectBoxContainer: {},
};

export default StagesSelection;
