// @flow

import { createAction } from "redux-actions";

export const GET_FACEBOOK_DATA = "GET_FACEBOOK_DATA";
export const GET_FACEBOOK_DATA_SUCCESS = "GET_FACEBOOK_DATA_SUCCESS";
export const REGISTER_SET_USER_PHONE_DATA_FOR_OTP =
  "REGISTER_SET_USER_PHONE_DATA_FOR_OTP";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER = "REGISTER";
export const LOGOUT_GOOGLE = "LOGOUT_GOOGLE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const VALIDATE_CUSTOMER = "VALIDATE_CUSTOMER";
export const ACCOUNT_CONFIRMATION = "ACCOUNT_CONFIRMATION";
export const RESET_NEW_PASSWORD = "RESET_NEW_PASSWORD";
export const GET_CUSTOMER_PROFILE = "GET_CUSTOMER_PROFILE";
export const SET_CUSTOMER_PROFILE = "SET_CUSTOMER_PROFILE";
export const UPDATE_CUSTOMER_PROFILE = "UPDATE_CUSTOMER_PROFILE";
export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const GET_META_DATA = "GET_META_DATA";
export const GET_META_DATA_SUCCESS = "GET_META_DATA_SUCCESS";
export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_UNIQUE = "GET_CITIES_UNIQUE";
export const SET_CITIES_UNIQUE = "SET_CITIES_UNIQUE";
export const GET_CITIES_UNIQUE_TRAIN = "GET_CITIES_UNIQUE_TRAIN";
export const SET_CITIES_UNIQUE_TRAIN = "SET_CITIES_UNIQUE_TRAIN";
export const GET_CITIES_SEARCH = "GET_CITIES_SEARCH";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITY_PAIRS = "GET_CITY_PAIRS";
export const SET_CITIES = "SET_CITIES";
export const ORIGIN_SEARCH_SELECTED = "ORIGIN_SEARCH_SELECTED";
export const DESTINATION_SEARCH_SELECTED = "DESTINATION_SEARCH_SELECTED";
export const SET_CITIES_FROM_HOME = "SET_CITIES_FROM_HOME";
export const SET_CITY_PAIRS_HOME = "SET_CITY_PAIRS_HOME";
export const SELECT_CITY_LIST_TYPE = "SELECT_CITY_LIST_TYPE";
export const SET_RECENT_CITIES = "SET_RECENT_CITIES";
export const SET_RECENT_CITIES_TRAIN = "SET_RECENT_CITIES_TRAIN";
export const SELECT_ORIGIN = "SELECT_ORIGIN";
export const SELECT_DESTINATION = "SELECT_DESTINATION";
export const ONWARD_DATE_CHANGE = "ONWARD_DATE_CHANGE";
export const RETURN_DATE_CHANGE = "RETURN_DATE_CHANGE";
export const SHOW_HIDE_BUS_LOADER = "SHOW_HIDE_BUS_LOADER";
export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_RETURN_SUCCESS = "GET_SERVICES_RETURN_SUCCESS";
export const GET_SERVICE_DETAILS = "GET_SERVICE_DETAILS";
export const GET_SERVICE_DETAILS_SUCCESS = "GET_SERVICE_DETAILS_SUCCESS";
export const SERVICE_SELECTED_ONWARD = "SERVICE_SELECTED_ONWARD";
export const SERVICE_SELECTED_RETURN = "SERVICE_SELECTED_RETURN";
export const SELECT_SEAT_ONWARD = "SELECT_SEAT_ONWARD";
export const SELECT_SEAT_RETURN = "SELECT_SEAT_RETURN";
export const SET_PASSENGER_DETAILS_ONWARD = "SET_PASSENGER_DETAILS_ONWARD";
export const SET_PASSENGER_DETAILS_RETURN = "SET_PASSENGER_DETAILS_RETURN";
export const SET_CONTACT_DETAILS = "SET_CONTACT_DETAILS";
export const SET_BOOKING_JSON_DETAILS = "SET_BOOKING_JSON_DETAILS";
export const SET_BOOKING_API_RESPONSE = "SET_BOOKING_API_RESPONSE";
export const BOOK_TENTATIVE_TICKET = "BOOK_TENTATIVE_TICKET";
export const SET_CONFIRM_TICKET_RESPONSE = "SET_CONFIRM_TICKET_RESPONSE";
export const GET_WALLET_RECHARGE_REQUEST = "GET_WALLET_RECHARGE_REQUEST";
export const GET_RECHARGE_LOCATIONS = "GET_RECHARGE_LOCATIONS";
export const GET_RECIPIENT_INFO = "GET_RECIPIENT_INFO";
export const UPDATE_FAVOURITES = "UPDATE_FAVOURITES";
export const TRANSFER_MONEY = "TRANSFER_MONEY";
export const REQUEST_MONEY = "REQUEST_MONEY";
export const SET_COORDS = "SET_COORDS";
export const SET_MARKERS = "SET_MARKERS";
export const GET_TICKET_DETAILS = "GET_TICKET_DETAILS";
export const SET_TICKET_DETAILS = "SET_TICKET_DETAILS";
export const CANCEL_TICKET = "CANCEL_TICKET";
export const CANCEL_TICKET_AT = "CANCEL_TICKET_AT";
export const GET_MY_TICKETS = "GET_MY_TICKETS";
export const SET_MY_TICKETS = "SET_MY_TICKETS";
export const GET_AT_TICKETS = "GET_AT_TICKETS";
export const SET_AT_TICKETS = "SET_AT_TICKETS";
export const GET_TRAIN_TICKETS = "GET_TRAIN_TICKETS";
export const SET_TRAIN_TICKETS = "SET_TRAIN_TICKETS";
export const GET_KUPOS_GO_TICKETS = "GET_KUPOS_GO_TICKETS";
export const SET_KUPOS_GO_TICKETS = "SET_KUPOS_GO_TICKETS";
export const GET_TICKET_DETAILS_KUPOS = "GET_TICKET_DETAILS_KUPOS";
export const GET_TXN_HISTORY = "GET_TXN_HISTORY";
export const SET_TXN_HISTORY = "SET_TXN_HISTORY";
export const GET_VIRTUAL_MONEY = "GET_VIRTUAL_MONEY";
export const SET_VIRTUAL_MONEY = "SET_VIRTUAL_MONEY";
export const APPLY_PROMO_CODE = "APPLY_PROMO_CODE";
export const GET_MARKER_ICONS = "GET_MARKER_ICONS";
export const SET_MARKER_MODAL = "SET_MARKER_MODAL";
export const SET_DEVICE_INFO = "SET_DEVICE_INFO";
export const SCAN_QR = "SCAN_QR";
export const SHOW_QR_BG = "SHOW_QR_BG";
export const GET_QR_INFO = "GET_QR_INFO";
export const GET_QR_IMAGE = "GET_QR_IMAGE";
export const SET_QR_IMAGE = "SET_QR_IMAGE";
export const SET_LOCAL_DATA = "SET_LOCAL_DATA";
export const SHOW_HIDE_CONTACT_MODAL = "SHOW_HIDE_CONTACT_MODAL";
export const GET_PLACES = "GET_PLACES";
export const SET_THIRD_PARTY_APP = "SET_THIRD_PARTY_APP";
export const GENERATE_OTP = "GENERATE_OTP";
export const VALIDATE_OTP = "VALIDATE_OTP";

//carpools actions -------
export const SET_USER_MODE = "SET_USER_MODE";
export const CARPOOL_SET_PICKUP_LOCATION = "CARPOOL_SET_PICKUP_LOCATION";
export const CARPOOL_SET_DROP_LOCATION = "CARPOOL_SET_DROP_LOCATION";
export const CARPOOL_DATE_CHANGE = "CARPOOL_DATE_CHANGE";
export const CARPOOL_TIME_CHANGE = "CARPOOL_TIME_CHANGE";
export const CARPOOL_GET_SEARCH_TRIPS = "CARPOOL_GET_SEARCH_TRIPS";
export const CARPOOL_SET_SEARCH_TRIPS = "CARPOOL_SET_SEARCH_TRIPS";
export const CARPOOL_UPLOAD_PIC = "CARPOOL_UPLOAD_PIC";
export const CARPOOL_BOOK_TRIP = "CARPOOL_BOOK_TRIP";
export const CARPOOL_SELECT_ONGOING_TRIP = "CARPOOL_SELECT_ONGOING_TRIP";
export const CARPOOL_CANCEL_TRIP = "CARPOOL_CANCEL_TRIP";
export const CARPOOL_RANK_TRIP = "CARPOOL_RANK_TRIP";
export const CARPOOL_GET_TRIPS = "CARPOOL_GET_TRIPS";
export const CARPOOL_SET_TRIPS = "CARPOOL_SET_TRIPS";
export const CARPOOL_GET_ORDER_DETAILS = "CARPOOL_GET_ORDER_DETAILS";
export const CARPOOL_SET_ORDER_DETAILS = "CARPOOL_SET_ORDER_DETAILS";

//carpool driver actions --
export const CARPOOL_DRIVER_REGISTER = "CARPOOL_DRIVER_REGISTER";
export const CARPOOL_DRIVER_SET_INFO = "CARPOOL_DRIVER_SET_INFO";
export const CARPOOL_DRIVER_SET_PICKUP_LOCATION =
  "CARPOOL_DRIVER_SET_PICKUP_LOCATION";
export const CARPOOL_DRIVER_SET_DROP_LOCATION =
  "CARPOOL_DRIVER_SET_DROP_LOCATION";
export const CARPOOL_DRIVER_DATE_CHANGE = "CARPOOL_DRIVER_DATE_CHANGE";
export const CARPOOL_DRIVER_TIME_CHANGE = "CARPOOL_DRIVER_TIME_CHANGE";
export const CARPOOL_DRIVER_SHOW_HIDE_TRIP_ALERT_MODEL =
  "CARPOOL_DRIVER_SHOW_HIDE_TRIP_ALERT_MODEL";
export const CARPOOL_DRIVER_CREATE_ROUTE = "CARPOOL_DRIVER_CREATE_ROUTE";
export const CARPOOL_DRIVER_CREATE_TRIP = "CARPOOL_DRIVER_CREATE_TRIP";
export const CARPOOL_DRIVER_SET_CREATED_TRIP =
  "CARPOOL_DRIVER_SET_CREATED_TRIP";
export const CARPOOL_DRIVER_PUBLISH_TRIP = "CARPOOL_DRIVER_PUBLISH_TRIP";
export const CARPOOL_DRIVER_START_TRIP = "CARPOOL_DRIVER_START_TRIP";
export const CARPOOL_DRIVER_ARRIVE_TRIP = "CARPOOL_DRIVER_ARRIVE_TRIP";
export const CARPOOL_DRIVER_FINISH_TRIP = "CARPOOL_DRIVER_FINISH_TRIP";
export const CARPOOL_DRIVER_GET_TRIPS = "CARPOOL_DRIVER_GET_TRIPS";
export const CARPOOL_DRIVER_SET_TRIPS = "CARPOOL_DRIVER_SET_TRIPS";
export const CARPOOL_DRIVER_SET_UPCOMING_HOME_TRIPS =
  "CARPOOL_DRIVER_SET_UPCOMING_HOME_TRIPS";
export const CARPOOL_DRIVER_GET_TRIP_DETAILS =
  "CARPOOL_DRIVER_GET_TRIP_DETAILS";
export const CARPOOL_DRIVER_SET_TRIP_DETAILS =
  "CARPOOL_DRIVER_SET_TRIP_DETAILS";
export const CARPOOL_DRIVER_GET_TRIP_ORDERS = "CARPOOL_DRIVER_GET_TRIP_ORDERS";
export const CARPOOL_DRIVER_SET_TRIP_ORDERS = "CARPOOL_DRIVER_SET_TRIP_ORDERS";
export const CARPOOL_DRIVER_UPDATE_LOCATION = "CARPOOL_DRIVER_UPDATE_LOCATION";

//airport transfer
export const AIRPORT_TRANSFER_SET_DATA = "AIRPORT_TRANSFER_SET_DATA";
export const AIRPORT_TRANSFER_GET_ROUTES = "AIRPORT_TRANSFER_GET_ROUTES";
export const AIRPORT_TRANSFER_SET_ROUTES = "AIRPORT_TRANSFER_SET_ROUTES";
export const AIRPORT_TRANSFER_SET_RETURN_ROUTES =
  "AIRPORT_TRANSFER_SET_RETURN_ROUTES";
export const AIRPORT_TRANSFER_BOOK = "AIRPORT_TRANSFER_BOOK";
export const AIRPORT_TRANSFER_SELECT_SERVICE_TO_BOOK =
  "AIRPORT_TRANSFER_SELECT_SERVICE_TO_BOOK";
export const AIRPORT_TRANSFER_RETURN_SERVICE_TO_BOOK =
  "AIRPORT_TRANSFER_RETURN_SERVICE_TO_BOOK";

//kupos go
export const KUPOS_GO_SET_DATA = "KUPOS_GO_SET_DATA";
export const KUPOS_GO_POST_TRIP_DETAILS = "KUPOS_GO_POST_TRIP_DETAILS";

//Envionment data
export const GET_ENVIRONMENT_THINGS = "GET_ENVIRONMENT_THINGS";
export const GET_TICKET_DETAILS_AT = "GET_TICKET_DETAILS_AT";

//BIP CARDS
export const ADD_BIP_CARD = "ADD_BIP_CARD";
export const GET_BIP_CARDS = "GET_BIP_CARDS";
export const SET_BIP_CARD_TO_EDIT = "SET_BIP_CARD_TO_EDIT";
export const UPDATE_BIP_CARD = "UPDATE_BIP_CARD";
export const DELETE_BIP_CARD = "DELETE_BIP_CARD";
export const SAVE_BIP_DETAILS = "SAVE_BIP_DETAILS";
export const RECHARGE_BIP_CARD = "RECHARGE_BIP_CARD";
export const VALIDATE_BIP_CARD = "VALIDATE_BIP_CARD";
export const GET_CARD_ANALYTICS = "GET_CARD_ANALYTICS";

//ONE CLICK CARDS
export const GET_CUSTOMER_CARDS = "GET_CUSTOMER_CARDS";
export const DELETE_MY_CARDS = "DELETE_MY_CARDS";
export const ADD_MY_CARDS = "ADD_MY_CARDS";

export const SET_ADD_TO_HOME_SCREEN = "SET_ADD_TO_HOME_SCREEN";

export const GET_CUSTOMER_INFO = "GET_CUSTOMER_INFO";
export const SAVE_TICKETS_TO_CANCEL = "SAVE_TICKETS_TO_CANCEL";
export const GET_AT_TERMINALS = "GET_AT_TERMINALS";
export const SET_AT_TERMINALS = "SET_AT_TERMINALS";
export const GET_SEAT_TYPES = "GET_SEAT_TYPES";
export const SET_SEAT_TYPES = "SET_SEAT_TYPES";
export const GET_AT_BLOCK_TIME = "GET_AT_BLOCK_TIME";
export const SET_AT_BLOCK_TIME = "SET_AT_BLOCK_TIME";

//DISCOUNT
export const GET_INSTANT_DISCOUNT = "GET_INSTANT_DISCOUNT";
export const SET_INSTANT_DISCOUNT = "SET_INSTANT_DISCOUNT";
//Corporate DISCOUNT
export const GET_CORPORATE_DISCOUNT = "GET_CORPORATE_DISCOUNT";
export const SET_CORPORATE_DISCOUNT = "SET_CORPORATE_DISCOUNT";

export const GET_OPERATOR_SITE = "GET_OPERATOR_SITE";
export const SET_OPERATOR_SITE = "SET_OPERATOR_SITE";


export const GET_SERVICES_V2 = "GET_SERVICES_V2";
export const GET_SERVICE_DETAILS_V2 = "GET_SERVICE_DETAILS_V2";


export const fetchDataActionCreators = {
  getFacebookUserData: createAction(GET_FACEBOOK_DATA),
  getFacebookUserDataSuccess: createAction(GET_FACEBOOK_DATA_SUCCESS),
  login: createAction(LOGIN),
  loginSuccess: createAction(LOGIN_SUCCESS),
  register: createAction(REGISTER),
  logoutGoogle: createAction(LOGOUT_GOOGLE),
  registerSetUserPhoneDataForOtp: createAction(
    REGISTER_SET_USER_PHONE_DATA_FOR_OTP
  ),
  validateCustomer: createAction(VALIDATE_CUSTOMER),
  forgotPassword: createAction(FORGOT_PASSWORD),
  accountConfirmation: createAction(ACCOUNT_CONFIRMATION),
  resetNewPassword: createAction(RESET_NEW_PASSWORD),
  getCustomerProfile: createAction(GET_CUSTOMER_PROFILE),
  updateCustomerProfile: createAction(UPDATE_CUSTOMER_PROFILE),
  setCustomerProfile: createAction(SET_CUSTOMER_PROFILE),
  openMenu: createAction(OPEN_MENU),
  closeMenu: createAction(CLOSE_MENU),
  showLoader: createAction(SHOW_LOADER),
  hideLoader: createAction(HIDE_LOADER),
  getMetaData: createAction(GET_META_DATA),
  getMetaDataSuccess: createAction(GET_META_DATA_SUCCESS),
  getCities: createAction(GET_CITIES),
  getCitiesUnique: createAction(GET_CITIES_UNIQUE),
  setCitiesUnique: createAction(SET_CITIES_UNIQUE),
  getCitiesUniqueTrain: createAction(GET_CITIES_UNIQUE_TRAIN),
  setCitiesUniqueTrain: createAction(SET_CITIES_UNIQUE_TRAIN),
  getCitiesSearch: createAction(GET_CITIES_SEARCH),
  getCitiesSuccess: createAction(GET_CITIES_SUCCESS),
  getCityPairs: createAction(GET_CITY_PAIRS),
  setCities: createAction(SET_CITIES),
  originSearchSelected: createAction(ORIGIN_SEARCH_SELECTED),
  destinationSearchSelected: createAction(DESTINATION_SEARCH_SELECTED),
  setCitiesFromHome: createAction(SET_CITIES_FROM_HOME),
  setCityPairsHome: createAction(SET_CITY_PAIRS_HOME),
  selectOrigin: createAction(SELECT_ORIGIN),
  selectDestination: createAction(SELECT_DESTINATION),
  selectedCityListType: createAction(SELECT_CITY_LIST_TYPE),
  setRecentCities: createAction(SET_RECENT_CITIES),
  setRecentCitiesTrain: createAction(SET_RECENT_CITIES_TRAIN),
  onwardDateChange: createAction(ONWARD_DATE_CHANGE),
  returnDateChange: createAction(RETURN_DATE_CHANGE),
  showHideBusLoader: createAction(SHOW_HIDE_BUS_LOADER),
  getServices: createAction(GET_SERVICES),
  getServicesSuccess: createAction(GET_SERVICES_SUCCESS),
  getServicesReturnSuccess: createAction(GET_SERVICES_RETURN_SUCCESS),
  getServiceDetails: createAction(GET_SERVICE_DETAILS),
  getServiceDetailsSuccess: createAction(GET_SERVICE_DETAILS_SUCCESS),
  serviceSelectedOnward: createAction(SERVICE_SELECTED_ONWARD),
  serviceSelectedReturn: createAction(SERVICE_SELECTED_RETURN),
  selectSeatOnward: createAction(SELECT_SEAT_ONWARD),
  selectSeatReturn: createAction(SELECT_SEAT_RETURN),
  setPassengerDetailsOnward: createAction(SET_PASSENGER_DETAILS_ONWARD),
  setPassengerDetailsReturn: createAction(SET_PASSENGER_DETAILS_RETURN),
  setContactDetails: createAction(SET_CONTACT_DETAILS),
  setBookingJSONDetails: createAction(SET_BOOKING_JSON_DETAILS),
  setBookingApiResponse: createAction(SET_BOOKING_API_RESPONSE),
  bookTentativeTicket: createAction(BOOK_TENTATIVE_TICKET),
  setConfirmTicketResponse: createAction(SET_CONFIRM_TICKET_RESPONSE),
  getWalletRechargeRequest: createAction(GET_WALLET_RECHARGE_REQUEST),
  getRechargeLocations: createAction(GET_RECHARGE_LOCATIONS),
  getRecipientInfo: createAction(GET_RECIPIENT_INFO),
  updateFavourites: createAction(UPDATE_FAVOURITES),
  transferMoney: createAction(TRANSFER_MONEY),
  requestMoney: createAction(REQUEST_MONEY),
  setCoords: createAction(SET_COORDS),
  setMarkers: createAction(SET_MARKERS),
  getTicketDetails: createAction(GET_TICKET_DETAILS),
  setTicketDetails: createAction(SET_TICKET_DETAILS),
  cancelTicket: createAction(CANCEL_TICKET),
  cancelTicketAT: createAction(CANCEL_TICKET_AT),
  getMyTickets: createAction(GET_MY_TICKETS),
  setMyTickets: createAction(SET_MY_TICKETS),
  getATTickets: createAction(GET_AT_TICKETS),
  setATTickets: createAction(SET_AT_TICKETS),
  getTrainTickets: createAction(GET_TRAIN_TICKETS),
  setTrainTickets: createAction(SET_TRAIN_TICKETS),
  getKuposGoTickets: createAction(GET_KUPOS_GO_TICKETS),
  getKuposTicketDetails: createAction(GET_TICKET_DETAILS_KUPOS),
  setKuposGoTickets: createAction(SET_KUPOS_GO_TICKETS),
  getTxnHistory: createAction(GET_TXN_HISTORY),
  setTxnHistory: createAction(SET_TXN_HISTORY),
  getVirtualMoney: createAction(GET_VIRTUAL_MONEY),
  setVirtualMoney: createAction(SET_VIRTUAL_MONEY),
  applyPromoCode: createAction(APPLY_PROMO_CODE),
  getMarkerIcons: createAction(GET_MARKER_ICONS),
  setMarkerModal: createAction(SET_MARKER_MODAL),
  setDeviceInfo: createAction(SET_DEVICE_INFO),
  scanQR: createAction(SCAN_QR),
  showQRBG: createAction(SHOW_QR_BG),
  getQRInfo: createAction(GET_QR_INFO),
  getQrImage: createAction(GET_QR_IMAGE),
  setQrImage: createAction(SET_QR_IMAGE),
  setLocalData: createAction(SET_LOCAL_DATA),
  showHideContactModal: createAction(SHOW_HIDE_CONTACT_MODAL),
  getPlaces: createAction(GET_PLACES),
  setThirdPartyApp: createAction(SET_THIRD_PARTY_APP),
  generateOtp: createAction(GENERATE_OTP),
  validateOtp: createAction(VALIDATE_OTP),

  //carpool actions
  setUserMode: createAction(SET_USER_MODE),
  carpoolSetPickupLocation: createAction(CARPOOL_SET_PICKUP_LOCATION),
  carpoolSetDropLocation: createAction(CARPOOL_SET_DROP_LOCATION),
  carpoolDateChange: createAction(CARPOOL_DATE_CHANGE),
  carpoolTimeChange: createAction(CARPOOL_TIME_CHANGE),
  carpoolGetSearchTrips: createAction(CARPOOL_GET_SEARCH_TRIPS),
  carpoolSetSearchTrips: createAction(CARPOOL_SET_SEARCH_TRIPS),
  uploadPic: createAction(CARPOOL_UPLOAD_PIC),
  carpoolBookTrip: createAction(CARPOOL_BOOK_TRIP),
  carpoolSelectOngoingTrip: createAction(CARPOOL_SELECT_ONGOING_TRIP),
  carpoolCancelTrip: createAction(CARPOOL_CANCEL_TRIP),
  carpoolRankTrip: createAction(CARPOOL_RANK_TRIP),
  carpoolSetTrips: createAction(CARPOOL_SET_TRIPS),
  carpoolGetTrips: createAction(CARPOOL_GET_TRIPS),
  carpoolGetOrderDetails: createAction(CARPOOL_GET_ORDER_DETAILS),
  carpoolSetOrderDetails: createAction(CARPOOL_SET_ORDER_DETAILS),

  //carpool driver actions
  carpoolDriverRegister: createAction(CARPOOL_DRIVER_REGISTER),
  carpoolDriverSetInfo: createAction(CARPOOL_DRIVER_SET_INFO),
  carpoolDriverSetPickupLocation: createAction(
    CARPOOL_DRIVER_SET_PICKUP_LOCATION
  ),
  carpoolDriverSetDropLocation: createAction(CARPOOL_DRIVER_SET_DROP_LOCATION),
  carpoolDriverDateChange: createAction(CARPOOL_DRIVER_DATE_CHANGE),
  carpoolDriverTimeChange: createAction(CARPOOL_DRIVER_TIME_CHANGE),
  carpoolDriverShowHideTripAlertModel: createAction(
    CARPOOL_DRIVER_SHOW_HIDE_TRIP_ALERT_MODEL
  ),
  carpoolDriverCreateRoute: createAction(CARPOOL_DRIVER_CREATE_ROUTE),
  carpoolDriverCreateTrip: createAction(CARPOOL_DRIVER_CREATE_TRIP),
  carpoolDriverSetCreatedTrip: createAction(CARPOOL_DRIVER_SET_CREATED_TRIP),
  carpoolDriverPublishTrip: createAction(CARPOOL_DRIVER_PUBLISH_TRIP),
  carpoolDriverStartTrip: createAction(CARPOOL_DRIVER_START_TRIP),
  carpoolDriverArriveTrip: createAction(CARPOOL_DRIVER_ARRIVE_TRIP),
  carpoolDriverFinishTrip: createAction(CARPOOL_DRIVER_FINISH_TRIP),
  carpoolDriverSetTrips: createAction(CARPOOL_DRIVER_SET_TRIPS),
  carpoolDriverSetUpcomingHomeTrips: createAction(
    CARPOOL_DRIVER_SET_UPCOMING_HOME_TRIPS
  ),
  carpoolDriverGetTrips: createAction(CARPOOL_DRIVER_GET_TRIPS),
  carpoolDriverGetTripDetails: createAction(CARPOOL_DRIVER_GET_TRIP_DETAILS),
  carpoolDriverSetTripDetails: createAction(CARPOOL_DRIVER_SET_TRIP_DETAILS),
  carpoolDriverGetTripOrders: createAction(CARPOOL_DRIVER_GET_TRIP_ORDERS),
  carpoolDriverSetTripOrders: createAction(CARPOOL_DRIVER_SET_TRIP_ORDERS),
  carpoolDriverUpdateLocation: createAction(CARPOOL_DRIVER_UPDATE_LOCATION),

  //airport transfer actions
  airportTransferSetData: createAction(AIRPORT_TRANSFER_SET_DATA),
  airportTransferGetRoutes: createAction(AIRPORT_TRANSFER_GET_ROUTES),
  airportTransferSetRoutes: createAction(AIRPORT_TRANSFER_SET_ROUTES),
  airportTransferSetReturnRoutes: createAction(
    AIRPORT_TRANSFER_SET_RETURN_ROUTES
  ),
  airportTransferBook: createAction(AIRPORT_TRANSFER_BOOK),
  airportTransferSelectServiceToBook: createAction(
    AIRPORT_TRANSFER_SELECT_SERVICE_TO_BOOK
  ),
  airportTransferReturnServiceToBook: createAction(
    AIRPORT_TRANSFER_RETURN_SERVICE_TO_BOOK
  ),

  //kupos go actions
  kuposGoSetData: createAction(KUPOS_GO_SET_DATA),
  kuposGoPostTripDetails: createAction(KUPOS_GO_POST_TRIP_DETAILS),

  //Environmental Data
  getEnvironmentThings: createAction(GET_ENVIRONMENT_THINGS),
  getTicketDetailsAT: createAction(GET_TICKET_DETAILS_AT),

  //BIP CARDS
  addBipCard: createAction(ADD_BIP_CARD),
  getBipCards: createAction(GET_BIP_CARDS),
  setBipCardToEdit: createAction(SET_BIP_CARD_TO_EDIT),
  updateBipCard: createAction(UPDATE_BIP_CARD),
  deleteBipCard: createAction(DELETE_BIP_CARD),
  saveBipDetails: createAction(SAVE_BIP_DETAILS),
  rechargeBipCard: createAction(RECHARGE_BIP_CARD),
  validateBipCard: createAction(VALIDATE_BIP_CARD),
  getCardAnalytics: createAction(GET_CARD_ANALYTICS),

  //ONE CLICK CARDS
  getCustomerCards: createAction(GET_CUSTOMER_CARDS),
  deleteMyCards: createAction(DELETE_MY_CARDS),
  addMyCards: createAction(ADD_MY_CARDS),

  setAddToHomeScreen: createAction(SET_ADD_TO_HOME_SCREEN),

  getCustomerInfo: createAction(GET_CUSTOMER_INFO),
  saveTicketsToCancel: createAction(SAVE_TICKETS_TO_CANCEL),
  getATTerminals: createAction(GET_AT_TERMINALS),
  setATTerminals: createAction(SET_AT_TERMINALS),
  getSeatTypes: createAction(GET_SEAT_TYPES),
  setSeatTypes: createAction(SET_SEAT_TYPES),
  getATBlockTime: createAction(GET_AT_BLOCK_TIME),
  setATBlockTime: createAction(SET_AT_BLOCK_TIME),

  //GET INSTANT DISCOUNT
  getInstantDiscount: createAction(GET_INSTANT_DISCOUNT),
  setInstantDiscount: createAction(SET_INSTANT_DISCOUNT),
  //GET CORPORATE DISCOUNT
  getCorporateDiscount: createAction(GET_CORPORATE_DISCOUNT),
  setCorporateDiscount: createAction(SET_CORPORATE_DISCOUNT),

  getOperatorSite: createAction(GET_OPERATOR_SITE),
  setOperatorSite: createAction(SET_OPERATOR_SITE),


  getServicesV2: createAction(GET_SERVICES_V2),
  getServiceDetailsV2: createAction(GET_SERVICE_DETAILS_V2),

};
