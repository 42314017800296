import React, { Component } from "react";
import { colors, dimensions } from "../theme";
import PBText from "./PBText";
import PBTouchable from "./PBTouchable";
import View from "./View";

class NoData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: "primary",
    };
  }

  render() {
    return (
      <View
        style={{ height: "calc(80vh - 100px)", width: "90vw", margin: "auto" }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "18vh",
          }}
        >
          <img
            style={{ width: "18vw", height: "auto" }}
            src={this.props.icon}
          />

          <View style={{ marginTop: 10 }}>
            <PBText center={true} bold={true}>
              {this.props.title}
            </PBText>
            <PBText center={true} style={{ marginTop: 3 }}>
              {this.props.desc}
            </PBText>
          </View>

          <View style={{ marginTop: 15 }}>
            <PBTouchable
              onPress={this.props.onPress ? this.props.onPress : null}
            >
              <View>
                <View
                  style={{
                    backgroundColor: "#FF5A5E",
                    borderRadius: 10,
                    height: 30,
                    padding: "0 15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PBText center={true} style={{ color: "#fff" }}>
                    {this.props.buttonText}
                  </PBText>
                </View>
              </View>
            </PBTouchable>
          </View>
        </View>
      </View>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  primaryBG: {
    backgroundColor: colors.primaryBG,
  },
  secondaryBG: {
    backgroundColor: colors.secondaryBG,
  },
  item: {
    height: 30,
    width: 30,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderTopWidth: 0,
    borderRightWidth: 10,
    borderBottomWidth: 20,
    borderLeftWidth: 10,
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "#FF5A5E",
    borderLeftColor: "transparent",
  },
};

export default NoData;
