// import EStyleSheet from 'react-native-extended-stylesheet';
import dimensions from './dimensions';

const commonStyles = {
    screenContainer: {
      flex: 1,
      backgroundColor: '$pageBG',
    },
    colorPrimary:{
      color: '$primaryBG',
    },
    colorWhite:{
      color:'#fff'
    },
    textColor:{
      color:'#777'
    },
    textDarkColor:{
      color:'#333'
    },
    inputLogin:{
      height:44
    },
    textCenter:{
      textAlign:'center'
    },
    hLine:{
      width:'100%',
      borderBottomWidth:1,
      borderBottomColor:'#ccc'
    },
    boxShadow:{
      shadowColor: "#000",
      shadowOffset: {
          width: 0,
          height: 5,
      },
      shadowOpacity: 0.6,
      shadowRadius: 14,

      elevation: 10,
      backgroundColor:'#fff',
    },
    whiteBg:{
      backgroundColor:'#fff'
    },
    whiteBG:{
      backgroundColor:'#fff'
    },
    carpoolCard:{
      marginBottom:10,marginTop:15,padding:20,width:dimensions.vw*100-60,marginLeft:30,backgroundColor:'#fff',
      borderRadius:10,
      shadowColor: "#444",
      shadowOffset: {
          width: 0,
          height: 5,
      },
      shadowOpacity: 0.6,
      shadowRadius: 14,
      elevation: 10,

    },
    carpoolInput:{
      height:dimensions.vw*11,
      borderRadius:12
    },
    titleSize:{
      fontSize:16
    },
    carpoolButtom:{height:dimensions.vw*11,borderRadius:12}
};


export default commonStyles;