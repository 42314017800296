import CommonService from '../../services/commonService';
import { Helmet } from 'react-helmet';
import React from 'react';
import { connect } from 'react-redux';

const KuposHelmet = ({ originCityDecoded, destCityDecoded }) => {
	// op stands for operator_sites

	const operator = JSON.parse(localStorage.getItem('op'));

	const renderHelmetOnOperatorBased = () => {
		if (operator) {
			return (
				<Helmet>
					<title>{operator.title}</title>
					<meta
						name="description"
						content={operator.description}
					/>
					<meta name="keywords" content={operator.keywords} />
					<link
						rel="icon"
						type="image/png"
						sizes="16x16"
						href={operator.favicon}
					/>
				</Helmet>
			);
		} else if (!operator && originCityDecoded && destCityDecoded) {
			return (
				<Helmet>
					<link
						rel="shortcut icon"
						href="/favicons/apple-icon-60x60.png"
					/>
					<link
						rel="apple-touch-icon"
						sizes="57x57"
						href="/favicons/apple-icon-57x57.png"
					/>
					<link
						rel="apple-touch-icon"
						sizes="60x60"
						href="/favicons/apple-icon-60x60.png"
					/>
					<link
						rel="apple-touch-icon"
						sizes="72x72"
						href="/favicons/apple-icon-72x72.png"
					/>
					<link
						rel="apple-touch-icon"
						sizes="76x76"
						href="/favicons/apple-icon-76x76.png"
					/>
					<link
						rel="apple-touch-icon"
						sizes="114x114"
						href="/favicons/apple-icon-114x114.png"
					/>
					<link
						rel="apple-touch-icon"
						sizes="120x120"
						href="/favicons/apple-icon-120x120.png"
					/>
					<link
						rel="apple-touch-icon"
						sizes="144x144"
						href="/favicons/apple-icon-144x144.png"
					/>
					<link
						rel="apple-touch-icon"
						sizes="152x152"
						href="/favicons/apple-icon-152x152.png"
					/>
					<link
						rel="apple-touch-icon"
						sizes="180x180"
						href="/favicons/apple-icon-180x180.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="192x192"
						href="/favicons/android-icon-192x192.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="32x32"
						href="/favicons/favicon-32x32.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="96x96"
						href="/favicons/favicon-96x96.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="16x16"
						href="/favicons/favicon-16x16.png"
					/>
					<title>{`kupos.cl | ${originCityDecoded} - ${destCityDecoded}`}</title>
					<meta
						name="description"
						content={`kupos.cl | ${originCityDecoded} - ${destCityDecoded}`}
					/>
				</Helmet>
			);
		}
		return (
			<Helmet>
				<link
					rel="shortcut icon"
					href="/favicons/apple-icon-60x60.png"
				/>
				<link
					rel="apple-touch-icon"
					sizes="57x57"
					href="/favicons/apple-icon-57x57.png"
				/>
				<link
					rel="apple-touch-icon"
					sizes="60x60"
					href="/favicons/apple-icon-60x60.png"
				/>
				<link
					rel="apple-touch-icon"
					sizes="72x72"
					href="/favicons/apple-icon-72x72.png"
				/>
				<link
					rel="apple-touch-icon"
					sizes="76x76"
					href="/favicons/apple-icon-76x76.png"
				/>
				<link
					rel="apple-touch-icon"
					sizes="114x114"
					href="/favicons/apple-icon-114x114.png"
				/>
				<link
					rel="apple-touch-icon"
					sizes="120x120"
					href="/favicons/apple-icon-120x120.png"
				/>
				<link
					rel="apple-touch-icon"
					sizes="144x144"
					href="/favicons/apple-icon-144x144.png"
				/>
				<link
					rel="apple-touch-icon"
					sizes="152x152"
					href="/favicons/apple-icon-152x152.png"
				/>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/favicons/apple-icon-180x180.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="192x192"
					href="/favicons/android-icon-192x192.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicons/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="96x96"
					href="/favicons/favicon-96x96.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicons/favicon-16x16.png"
				/>
				<title>
					kupos.com | Compra pasajes de bus, tren, transfer, bip!
					y más
				</title>
				<meta
					name="description"
					content="Cotiza y compra pasajes de bus, tren, transfer y recarga bip! de la manera más fácil. Más de 200 empresas y todo el transporte terrestre en un solo lugar."
				/>
			</Helmet>
		);
	};

	return (
		<div className="common-kupos-helmet">
			{renderHelmetOnOperatorBased()}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		home: state.home,
	};
}
export default connect(mapStateToProps, {})(KuposHelmet);
