import React from 'react'
import tepualRoutes from './tepualRoutes';
import saoPauloRoutes from './saoPauloRoutes';
import viaturRoutes from './viaturRoutes';
import kennyBusRoutes from './kennyBusRoutes';
import AndimarRoutes from './andimarRoutes';

const routes = {
    tepual: tepualRoutes,
    saopaulo: saoPauloRoutes,
    viatur: viaturRoutes,
    kennybus: kennyBusRoutes,
    andimar: AndimarRoutes,
}


const getRoutes = () => {
    const operator = JSON.parse(localStorage.getItem("op"));
    // const name = operator && operator.domain_url ? `${operator.domain_url.split('.')[0]}` : ''
    const name = operator && operator.operator_name ? `${operator.operator_name}` : ''

    const data = routes[name]

    return data ? data : []
}

export default getRoutes
