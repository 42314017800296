const AppData = {
	// AT_URL: "https://at.kupos.cl/",
	// AT_URL: "http://3.81.28.102:4000/",
	AT_URL: 'https://stg.at.kupos.cl/',
	// BASE_URL: "https://pbstage.kupas.cl/",
	// BASE_URL: "https://busstage1.kupas.cl/",
	// BASE_URL: "https://tepual.newstg2-bus.kupos.cl/",
	// BASE_URL: "http://tepual.newstg2-opsdweb.kupos.cl/",
	BASE_URL: 'https://newstg1-bus.kupos.cl/',

	// BASE_URL: "https://pbstage.kupas.cl/",
	// BASE_URL: "https://api.pasajebus.com/",
	ONE_SIGNAL_APP_ID: '234fd2db-ea84-4b59-ab4b-28a3ea826474',
	// BIP_URL: "https://stg2.bip.kupos.cl/",
	SENDER_ID: '730867902568',
	CARPOOL_URL: ' ',
	MAP_API_KEY: 'AIzaSyDngZWWwpM0zvheSjUvYu3RlnGnyiSQQ0w',
	// FB_APP_ID: "177184106959636",//
	FB_APP_ID: '409783556699660',
	// BASE_URL_V2: "https://at.kupos.cl/api/v2/",
	// BASE_URL_V3: "https://at.kupos.cl/api/v3/",
	BASE_URL_V2: 'https://stg2.at.kupos.cl/api/v2/',
	BASE_URL_V3: 'https://stg2.at.kupos.cl/api/v3/',
	// X_OP_APP_DOMAIN: "tepual.newstg2-opsdweb.kupos.cl",
	// X_OP_APP_DOMAIN: "andimar.newstg2-opsdweb.kupos.cl",
	// X_OP_APP_DOMAIN: "saopaulo.newstg2-opsdweb.kupos.cl",
	// X_OP_APP_DOMAIN: "viatur.pasaje.cl",
	// X_OP_APP_DOMAIN: "kennybus.pasaje.cl",
	// X_OP_APP_DOMAIN: "andimar.pasaje.cl",
	X_OP_APP_DOMAIN: window.location.host,

};
export default AppData;
