import React, { PureComponent } from "react";
import PBText from "./PBText";
import PButton from "./PButton";
import PBTouchable from "./PBTouchable";

class ATPassengerModal extends PureComponent {
  /**
   * type:string
   * showModal:bool
   * icon:imagePath
   * titleText:string
   * subtitle:string
   * bodyText:string
   * showButton1:bool
   * showButton2:bool
   * button1Text:string
   * button2Text:string
   * button1Press:func
   * button2Press:func
   * button1Bold:bool
   * button2Bold:bool
   * onBackButtonPress:func
   * onBackdropPress:func
   * buttonTextStyle
   * closeIconPress
   * showCloseIcon
   * onModalIconPress
   */

  constructor(props) {
    super(props);
  }

  render() {
    return this.props.showModal ? (
      <div
        // backdropOpacity={this.props.opacity ? this.props.opacity : 0.5}
        // isOpen={this.props.showModal}
        // show-backdrop="true"
        // disabled={this.props.showModal}
        // animated="true"
        // isVisible={this.props.showModal}
        // onBackButtonPress={this.props.onBackButtonPress ? this.props.onBackButtonPress : () => {} }
        // onBackdropPress={this.props.onBackdropPress ? this.props.onBackdropPress : () => {} }
        // animationInTiming={this.props.animationTime || 300}
        // animationOutTiming={this.props.animationTime || 300}
        // backdropTransitionInTiming={this.props.animationTime || 300}
        // backdropTransitionOutTiming={this.props.animationTime || 300}
        className="pb-modal"
      >
        <div className="pb-modal-content" style={{ bottom: this.props.bottom }}>
          <div
            className="kupos-card pb-modal-container"
            style={{
              padding:
                this.props.padding === 0 || this.props.padding
                  ? this.props.padding
                  : 20,
              width:
                this.props.width === 0 || this.props.width
                  ? this.props.width
                  : "82vw",
              borderRadius:
                this.props.borderRadius === 0 || this.props.borderRadius
                  ? this.props.borderRadius
                  : 20,
              position: "relative",
            }}
          >
            <div style={styles.body}>{this.props.children}</div>

            {this.props.showCloseIcon ? (
              <PBTouchable onPress={this.props.closeIconPress}>
                <div
                  style={{
                    padding: 5,
                    borderRadius: 16,
                    position: "absolute",
                    top: 25,
                    right: 25,
                  }}
                >
                  <img height={22} src={"../assets/carpool/cross.png"} />
                </div>
              </PBTouchable>
            ) : null}
          </div>
        </div>
        <div
          className="modal-back-drop"
          onClick={
            this.props.onBackButtonPress
              ? this.props.onBackButtonPress
              : () => {}
          }
        ></div>
      </div>
    ) : null;
  }
}

const styles = {
  modalOuter: {
    width: "90%",
  },
  iconContainer: {
    width: "100%",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    height: 80,
    display: "flex",
  },
  titleContainer: {
    alignItems: "center",
    padding: 0,
    marginTop: "10px",
  },
  titleText: {
    textAlign: "center",
    fontSize: 16,
  },
  alertBodyTextContainer: {
    alignItems: "center",
  },
  alertBodyText: {
    textAlign: "center",
    fontSize: 15,
  },

  buttonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
  },
  buttonContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "70%",
    alignSelf: "center",
  },
};

export default ATPassengerModal;
