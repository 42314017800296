import React, { PureComponent } from "react";

import commonStyles from "../../theme/commonStyles";
import DateService from "../../services/dateService";

import { dimensions } from "../../theme";
import PBText from "../PBText";

class BusLoader extends PureComponent {
  interval;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { from, to, travelDate } = this.props;
    return (
      <div className="loader-container" style={this.props.loaderStyle}>
        {/* <img
          src={"../assets/animation-screen/bg.png"}
          style={{ width: "100%", height: "100%" }}
        />
        <div
          className="loader-from-to-container"
        >
          <PBText
            fontSize={"font16"}
            textAlign={"text-center"}
          >
            {from.toUpperCase()} - {to.toUpperCase()}
          </PBText>
        </div>
        <div className="loader-travel-date" 
        >
          <PBText style={{ fontSize: 16, textAlign: "center" }}>
            {travelDate ? DateService.getServiceHeaderDateFormat(travelDate).toUpperCase() : ''}
          </PBText>
        </div>
        <div 
            className="animation-container" 
            >
          <img

            className="loader-scene-img"
            src={"../../assets/animation-screen/background-scene.png"}
          />
          <img width={60} src={"../assets/animation-screen/bus.png"} />
          <img
            width={40}
            src={"../assets/animation-screen/drop-point.png"}
          />
        </div>
        <img
          className="loader-white-line"
          width={100}
          style={{ marginTop: 10 }}
          src={"../assets/animation-screen/white-line.png"}
        /> */}
        <div className="services-gif-loader-container">
          <img src={"/assets/animation-screen/PasajeBus.gif"} />
        </div>
        <div style={{ marginTop: 20, alignItems: "center" }}>
          <PBText fontSize={"font16"} textAlign={"text-center"}>
            CARGANDO SERVICIOS...
          </PBText>
        </div>
      </div>
    );
  }
}

const styles = {
  loaderContainer: {
    flex: 1,
  },
  loaderFromToContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 80,
    textAlign: "center",
  },
  loaderTravelDate: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 5,
  },
  busIcon: {
    position: "absolute",
    bottom: 0,
  },

  animationContainer: {
    marginTop: 10,
  },
};

export default BusLoader;
