import React from 'react'
import { Route } from 'react-router-dom';

const KennyBusContactUs = React.lazy(() => import("../theme3/KennyBusPages/ViaturContactUs"));


const viaturRoutes = [
    <Route exact path="/contact" component={KennyBusContactUs} />

]


export default viaturRoutes
