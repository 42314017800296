// @flow

import React, { PureComponent } from "react";

import { connectData } from "../../redux";

import DateService from "../../services/dateService";
import {
  ServiceListItemContainer,
  TravelDetailsHeader,
  SortStrip,
  PBText,
  BusLoader,
  PBModal,
} from "../../components";

// import { SERVICE_DETAILS_RETURN_SCREEN } from '../../navigation/Screens';
import { CommonService, DateSerivce, storageService } from "../../services";
import {
  IonPage,
  IonContent,
  withIonLifeCycle,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from "@ionic/react";
import { Link } from "react-router-dom";
import KuposHeader from "../../components/KuposHeader";

class ServiceListReturnScreenV2 extends PureComponent {
  orderByField;
  order;
  orderBy;
  routesFull;
  firstLoad = true;
  capsuleMenuIcons = [
    {
      icon: "../assets/icons/service-sort/icon-star-line.png",
      iconActive: "../assets/icons/service-sort/icon-star-line-pink.png",
      label: "Ranking",
      sortFeild: "rating",
    },
    {
      icon: "../assets/icons/service-sort/icon-bus-line.png",
      iconActive: "../assets/icons/service-sort/icon-bus-line-pink.png",
      label: "Empresas",
      sortFeild: "operator_service_name",
    },
    {
      icon: "../assets/icons/service-sort/icon-clock-line.png",
      iconActive: "../assets/icons/service-sort/icon-clock-line-pink.png",
      label: "Horarios",
      sortFeild: "dep_time",
    },
    {
      icon: "../assets/icons/service-sort/icon-chronometer-line.png",
      iconActive: "../assets/icons/service-sort/icon-chronometer-line-pink.png",
      label: "Rápido",
      sortFeild: "duration",
    },
    {
      icon: "../assets/icons/service-sort/icon-piggybank-line.png",
      iconActive: "../assets/icons/service-sort/icon-piggybank-line-pink.png",
      label: "Precio",
      sortFeild: "fare_str",
    },
  ];
  constructor(props) {
    super(props);
    this.setDataFromLocal();
    this.state = {
      getServiceListPending: true,
      servicesLoaded: false,
      routes: null,
      soldRoutes: null,
      orderBy: "DESC",
      orderByField: "dep_time",
      animationMargin: 0,
      activeSortTab: 2,
      showModal: true,
    };
    // CommonService.bindNav(this);
  }

  setDataFromLocal = () => {
    if (
      !this.props.data.bookingDetailsOnward ||
      !this.props.data.bookingDetailsOnward.selectedSeatsNumbers
    ) {
      let data = JSON.parse(storageService.getItem("passengerData"));
      let metaData = JSON.parse(storageService.getItem("metaData"));
      let loginData = JSON.parse(storageService.getItem("loginData"));
      this.props.setLocalData(data);
      this.data = { ...{ metaData: metaData, loginData: loginData }, ...data };
      console.log(this.data);
    } else {
      this.data = this.props.data;
    }
  };

  navigationButtonPressed({ buttonId }) {
    CommonService.rightButonHome(
      buttonId,
      this.props.componentId,
      this.props.returnDateChange
    );
  }

  componentDidMount() {
  }

  ionViewWillEnter() {
    this.props.selectSeatReturn({
      totalBookingAmount: 0,
      netBookingAmount: 0,
      discountAmount: 0,
      selectedSeatsToBook: null,
      selectedSeatsNumbers: null,
      selectedSeatTypes: null,
    });
    this.props.setPassengerDetailsReturn([]);
    CommonService.clearCoachLayoutSelectedSeats(
      this.props.data.selectedServiceReturn,
      this.props.serviceSelectedReturn
    );
    if (this.props.data.selectedOrigin && this.props.data.selectedDestination && !CommonService.fullServiceListV2) {
      this.servicesCalled = true;
      this.getServices(this.props.data.selectedReturnDate);
      console.log(
        "Service List Screen Onward date",
        this.props.data.selectedReturnDate
      );
    } else if (CommonService.fullServiceListV2) {
      this.setServices(CommonService.fullServiceListV2.return_schedules)
    }
  }

  componentDidUpdate() {
    // const { origin, dest, dateOnward, dateReturn, step } =
    //   this.props.match.params;
    // console.log("this.props.match.params", this.props.data);
    // if (!this.servicesCalled && this.props.data.citiesUnique) {
    //   let checkOriginCity = origin
    //     .split("--")
    //     .join("+")
    //     .split("-")
    //     .join(" ")
    //     .split("+")
    //     .join("-")
    //     .toLowerCase();
    //   let checkDestCity = dest
    //     .split("--")
    //     .join("+")
    //     .split("-")
    //     .join(" ")
    //     .split("+")
    //     .join("-")
    //     .toLowerCase();
    //   let filterCities = this.props.data.citiesUnique.filter((it) => {
    //     return (
    //       it[0].toLowerCase() == checkOriginCity ||
    //       it[0].toLowerCase() == checkDestCity
    //     );
    //   });
    //   if (
    //     filterCities &&
    //     !this.props.data.selectedOrigin &&
    //     !this.props.data.selectedDestination
    //   ) {
    //     let originCity = filterCities.find((it) => {
    //       return it[0].toLowerCase() == checkOriginCity;
    //     });
    //     let selectedCityOrigin = {
    //       id: originCity[1],
    //       name: originCity[0].split(",")[0],
    //       country: originCity[0].split(",")[1],
    //       full_name: originCity[0],
    //     };
    //     this.props.selectOrigin(selectedCityOrigin);

    //     let destCity = filterCities.find((it) => {
    //       return it[0].toLowerCase() == checkDestCity;
    //     });
    //     let selectedCityDest = {
    //       id: destCity[1],
    //       name: destCity[0].split(",")[0],
    //       country: destCity[0].split(",")[1],
    //       full_name: destCity[0],
    //     };
    //     this.props.selectDestination(selectedCityDest);
    //     this.props.returnDateChange(dateReturn);
    //     this.servicesCalled = true;
    //     this.getServices(dateReturn, selectedCityOrigin, selectedCityDest);
    //   } else if (!this.servicesCalled) {
    //     this.servicesCalled = true;
    //     this.getServices(dateReturn);
    //   }
    // }
  }

  getServices = (
    newDate,
    origin = null,
    dest = null,
    event = null,
    hideLoader,
    retryCount = 0
  ) => {
    console.log("New Date is", newDate);

    this.routesFull = null;
    let path =
      "origin_id=" + (dest ? dest.id : this.props.data.selectedDestination.id);
    path +=
      "&destination_id=" +
      (origin ? origin.id : this.props.data.selectedOrigin.id);
    path +=
      "&travel_date=" +
      DateSerivce.changeDateFormat(newDate, "dd-mm-yyyy", "yyyy-mm-dd");
    path += "&show_only_available_services=false&show_injourney_services=true";
    path += `&is_round_trip=true&is_roundtrip_return=true&retry_count=${retryCount}`;
    this.props.getServices({
      path: path, callback: (result) => {

        this.retryFlag = result.retryFlag;
        this.waitTime = result.waitTime;
        if (!this.serviceLoaded) {
          this.maxRetryCount = result.maxRetryCount;
          this.serviceLoaded = true;
        }
        if (Array.isArray(result.routes) && result.routes.length) {
          this.maxRetryCount = 0;
        }
        if (this.retryFlag && this.maxRetryCount > 0) {
          this.maxRetryCount--
          retryCount++
          setTimeout(() => {
            this.getServices(newDate, origin, dest, event, hideLoader, retryCount)
          }, this.waitTime * 1000)
        }
        this.setServices(result, event)
      },
      hideLoader: hideLoader,
    });
  };

  setServices = (services, event = null) => {
    this.setState({
      getServiceListPending: false,
    })
    if (event) {
      event.detail.complete();
    }
    this.props.getServicesReturnSuccess(services.routes ? services : { routes: services, v2: true });
    this.setRoutes();
    this.firstLoad = false;
  };

  setRoutes = () => {
    this.routesFull = this.props.data.serviceListReturn
      ? this.props.data.serviceListReturn.routes
      : null;
    if (this.routesFull) {
      this.saperateRoutesAndSoldRoutes();
    }
  };

  saperateRoutesAndSoldRoutes = () => {
    this.sortBy(this.state.activeSortTab);
  };

  dayChange = (type) => {
    console.log(" this.props.data----", this.props.data);
    console.log(" type----", this.props.data);
    this.dayChangePressed = true;
    this.serviceLoaded = false;
    if (type == 1) {
      if (
        this.props.data.selectedOnwardDate >= this.props.data.selectedReturnDate
      ) {
        return;
      }
      this.prevDate();
    } else if (type == 2) {
      // if (DateService.getTodayString() == this.props.data.selectedReturnDate) {
      //   return;
      // }
      this.nextDate();
    }
  };

  /**
   * go to the next date
   */
  nextDate = () => {
    let newDate = DateService.addDate(
      this.props.data.selectedReturnDate,
      1,
      "dd-mm-yyyy"
    );
    this.props.returnDateChange(newDate);
    this.getServices(newDate);
  };

  /**
   * go to the previous date
   */
  prevDate = () => {
    let newDate = DateService.addDate(
      this.props.data.selectedReturnDate,
      -1,
      "dd-mm-yyyy"
    );
    this.props.returnDateChange(newDate);
    this.getServices(newDate);
  };

  sortBy = (i) => {
    let orderBy = i == 1 || i == 2 || i == 4 ? "DESC" : "ASC";
    let sortFeild = this.capsuleMenuIcons[i].sortFeild;
    if (this.dateChanged) {
      orderBy = this.state.orderBy;
    }
    if (this.state.orderByField == sortFeild && !this.dayChangePressed) {
      if (this.state.orderBy === "ASC") {
        orderBy = "DESC";
      } else if (
        this.state.orderBy === "DESC" &&
        (i == 1 || i == 2 || i == 4)
      ) {
        orderBy = "ASC";
      }
    }
    this.dayChangePressed = false;

    let routesFull = this.sortThis(this.routesFull, sortFeild, orderBy);
    let routes = routesFull.filter((it) => {
      return it.available_seats > 0;
    });
    let soldRoutes = routesFull.filter((it) => {
      return it.available_seats <= 0;
    });
    for (let route of soldRoutes) {
      route.soldRoute = true;
    }
    // routes.routes = routesFull;
    this.setState({
      orderByField: sortFeild,
      orderBy: orderBy,
      activeSortTab: i,
      serviceList: routes,
      routes: routes,
      soldRoutes: soldRoutes,
      routesFull: routesFull,
    });
  };

  /**
   * ascending descending method
   */
  isAsc = () => {
    if (this.state.orderBy === "ASC") {
      return true;
    } else {
      return false;
    }
  };

  /**
   * sort method
   */
  sortThis = (arr, orderByValue, orderType = "ASC") => {
    arr.sort((a, b) => {
      if (orderType === "ASC") {
        if (orderByValue == "operator_service_name") {
          if (a[orderByValue] < b[orderByValue]) return 1;
          if (a[orderByValue] > b[orderByValue]) return -1;
          return 0;
        } else if (orderByValue == "dep_time") {
          // return (Date.parse('1970/01/01 ' + a[orderByValue]) - Date.parse('1970/01/01 ' + b[orderByValue]))
          if (a[orderByValue] < b[orderByValue]) return -1;
          if (a[orderByValue] > b[orderByValue]) return 1;
          return 0;
        } else if (orderByValue == "rating") {
          if (a.operator_details[1] < b.operator_details[1]) return 1;
          if (a.operator_details[1] > b.operator_details[1]) return -1;
          return 0;
        } else if (orderByValue == "duration") {
          if (a[orderByValue] < b[orderByValue]) return 1;
          if (a[orderByValue] > b[orderByValue]) return -1;
          return 0;
        } else {
          if (a[orderByValue] && b[orderByValue]) {
            let aValue = a[orderByValue].split(":");
            let bValue = b[orderByValue].split(":");
            if (Number.parseInt(aValue[1]) < Number.parseInt(bValue[1]))
              return -1;
            if (Number.parseInt(aValue[1]) > Number.parseInt(bValue[1]))
              return 1;
          }
          return 0;
        }
      } else {
        if (orderByValue == "operator_service_name") {
          if (a[orderByValue] > b[orderByValue]) return 1;
          if (a[orderByValue] < b[orderByValue]) return -1;
          return 0;
        } else if (orderByValue == "dep_time") {
          // return -(Date.parse('1970/01/01 ' + a[orderByValue]) - Date.parse('1970/01/01 ' + b[orderByValue]))
          if (a[orderByValue] < b[orderByValue]) return 1;
          if (a[orderByValue] > b[orderByValue]) return -1;
          return 0;
        } else if (orderByValue == "rating") {
          if (a.operator_details[1] > b.operator_details[1]) return 1;
          if (a.operator_details[1] < b.operator_details[1]) return -1;
          return 0;
        } else if (orderByValue == "duration") {
          if (a[orderByValue] > b[orderByValue]) return 1;
          if (a[orderByValue] < b[orderByValue]) return -1;
          return 0;
        } else {
          if (a[orderByValue] && b[orderByValue]) {
            let aValue = a[orderByValue].split(":");
            let bValue = b[orderByValue].split(":");
            if (Number.parseInt(aValue[1]) > Number.parseInt(bValue[1]))
              return -1;
            if (Number.parseInt(aValue[1]) < Number.parseInt(bValue[1]))
              return 1;
          }
          return 0;
        }
      }
    });
    return arr;
  };


  checkMidnight = (service) => {
    if (service.dep_validation_text) {

      this.setState({
        showModal: true,
        modalTitle: '',
        modalBody: service.dep_validation_text,
        showButton1: true,
        buttonText1: 'Escoger otro viaje',
        onButtonClick1: this.closeModal,
        showButton2: true,
        buttonText2: 'CONTINUE',
        onButtonClick2: () => {
          this.closeModal();
          this.onServiceSelect(service)
        },
      });
      return
    } else {
      this.onServiceSelect(service)
    }
  }


  onServiceSelect = (service) => {
    if (service.available_seats <= 0) {
      this.setState({
        showToast1: true,
        toastMessage: "Agotado - Este servicio ya no está disponible",
      });

      setTimeout(() => {
        this.setState({
          showToast1: false,
        });
      }, 3000);
      return false;
    }
    this.getServiceDetails(service.id, service);
  };

  getServiceDetails(scheduleId, route) {
    // store

    let data = {
      returnTrip: true,
      returnTripSearch: true,
      scheduleId: scheduleId,
    };
    this.props.getServiceDetails({
      callback: (success, message) => {
        if (success) this.onServiceDetailsResponse(success, scheduleId, route);
        else {
          this.setState({
            showToast1: true,
            toastMessage:
              message || "No disponible. Por favor selecciona otro servicio",
          });

          setTimeout(() => {
            this.setState({
              showToast1: false,
            });
          }, 3000);
        }
        this.getServices(
          this.props.data && this.props.data.selectedOnwardDate,
          null,
          null,
          null,
          true
        );
      },
      data: data,
      isReturn: true,
    });
  }

  onServiceDetailsResponse = (success, scheduleId, route) => {
    if (success) {
      this.checkSeatAvailability(
        this.props.data.serviceDetails,
        scheduleId,
        route
      );
    }
  };

  checkSeatAvailability = (serviceDetails, scheduleId, route) => {
    if (serviceDetails) {
      if (serviceDetails.total_available_seats > 0) {
        this.storeLocalData();
        this.props.serviceSelectedReturn(
          CommonService.setSeatLayout(serviceDetails, route)
        );
        let source = this.props.data.selectedOrigin.full_name
          .toLowerCase()
          .replace(/-/g, "_")
          .replace(/ /g, "-")
          .replace(/_/g, "--");
        let dest = this.props.data.selectedDestination.full_name
          .toLowerCase()
          .replace(/-/g, "_")
          .replace(/ /g, "-")
          .replace(/_/g, "--");
        let link;
        link = `/${CommonService.isTrain() ? "es/pasajes-tren" : "es/pasajes-bus"
          }/select-seat/${this.props.data.selectedServiceOnward.route.id
          }/${scheduleId}/${source}/${dest}/${this.props.data.selectedOnwardDate
          }/${this.props.data.selectedReturnDate}/2`;
        this.props.history.push(link);
        // CommonService.goToScreen(this.props.componentId,SERVICE_DETAILS_RETURN_SCREEN,'Selecciona tu asiento')
      } else {
        this.setState({
          showToast1: true,
          toastMessage: "Agotado - Este servicio ya no está disponible",
        });

        setTimeout(() => {
          this.setState({
            showToast1: false,
          });
        }, 3000);
        let list = JSON.parse(
          JSON.stringify(this.props.data.serviceListReturn)
        );
        for (let i in list.routes) {
          if (list.routes[i].id == scheduleId) {
            list.routes[i].available_seats = 0;
            this.props.getServicesSuccess(list);
            break; //Stop this loop, we found it!
          }
        }
        this.setRoutes();
      }
    }
  };
  storeLocalData = () => {
    let data = {
      selectedOrigin: this.props.data.selectedOrigin,
      selectedDestination: this.props.data.selectedDestination,
      selectedOnwardDate: this.props.data.selectedOnwardDate,
      selectedReturnDate: this.props.data.selectedReturnDate,
      serviceDetails: this.props.data.serviceDetails,
      selectedServiceOnward: this.props.data.selectedServiceOnward,
      selectedServiceReturn: this.props.data.selectedServiceReturn,
      bookingDetailsOnward: this.props.data.bookingDetailsOnward,
      bookingDetailsReturn: this.props.data.bookingDetailsReturn,
      passengerDetailsOnward: this.props.data.passengerDetailsOnward,
      passengerDetailsReturn: this.props.data.passengerDetailsReturn,
      contactDetails: this.props.data.contactDetails,
      bookingJSONDetails: this.props.data.bookingJSONDetails,
    };
    storageService.setItem("passengerData", JSON.stringify(data));
  };


  closeModal = () => {
    this.setState({
      showModal: false,
      modalTitle: '',
      modalBody: '',
      onButtonClick1: null,
      showButton2: false,
      buttonText2: null,
      modalIcon: null,
      modalChild: null,
      showCloseIcon: false,
    });
  };

  render() {
    // if (
    //   !this.routesFull &&
    //   this.firstLoad &&
    //   this.props.data &&
    //   this.props.data.serviceListReturn &&
    //   this.props.data.serviceListReturn.routes
    // ) {
    //   this.setRoutes();
    // }
    return this.props.data && this.props.data.showBusLoader && !this.state.getServiceListPending ? (
      <IonPage>
        <div className="service-list-header">
          <KuposHeader
            title='Selecciona'
            boldTitle='tu servicio'
            handleBackSubmit={() => this.props.history.goBack()}
            handleHomeSubmit={() => {
              this.props.history.replace("", null);
              this.props.history.push("/");
            }}
          />


          <TravelDetailsHeader
            from={this.props.data.selectedDestination.name}
            to={this.props.data.selectedOrigin.name}
            travelDate={DateSerivce.changeDateFormat(
              this.props.data.selectedReturnDate,
              "dd-mm-yyyy",
              "yyyy-mm-dd"
            )}
            onDayChange={(type) => this.dayChange(type)}
          />
          <SortStrip
            onSort={(sortType) => this.sortBy(sortType)}
            sortType={this.state.orderBy}
            sortFeild={this.state.orderByField}
            capsuleMenuIcons={this.capsuleMenuIcons}
            activeTab={this.state.activeSortTab}
          />
        </div>
        <IonContent>
          <div className="service-list-container">
            <ServiceListItemContainer
              routes={this.state.routes}
              soldRoutes={this.state.soldRoutes}
              amenities={this.props.data.metaData.amenities}
              onServiceSelect={(service) => this.checkMidnight(service)}
            />

            <PBModal
              showModal={this.state.showModal}
              type="alert"
              showButton1={true}
              button1Text={"OK, CONTINUAR"}
              button1Press={() => this.setState({ showModal: false })}
              icon={"../../assets/carpool/calendar_new.svg"}
            >
              <div
                style={{
                  padding: 10,
                  margin: 20,
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 10,
                }}
              >
                <PBText
                  fontColor={"dark-font"}
                  fontSize={"font15"}
                  textAlign={"text-center"}
                  fontStyle={"bold-font"}
                  style={{ marginBottom: 10 }}
                >
                  Selecciona tu viaje de vuelta
                </PBText>
                <PBText
                  fontColor={"light-grey-font"}
                  fontSize={"font14"}
                  textAlign={"text-center"}
                >
                  Por favor selecciona el servicio para tu viaje de vuelta
                </PBText>
              </div>
            </PBModal>
          </div>
          <IonRefresher
            slot="fixed"
            onIonRefresh={(event) =>
              this.getServices(
                this.props.data.selectedReturnDate,
                null,
                null,
                event
              )
            }
          >
            <IonRefresherContent
              pullingText="Desliza para actualizar"
              refreshingSpinner="circles"
              refreshingText="Cargando..."
            ></IonRefresherContent>
          </IonRefresher>

          <IonToast
            isOpen={this.state.showToast1}
            // onDidDismiss={this.setState({ showToast1: false })}
            message={this.state.toastMessage}
            duration={2000}
          />
          {/* <PBModal
            showModal={this.state.showModal}
            bodyText={this.state.modalBody}
            title={this.state.modalTitle}
            bodyText={this.state.modalBody}
            type="alert"
            showButton1={true}
            button1Text={this.state.buttonText1}
            button1Press={this.state.onButtonClick1 ? this.state.onButtonClick1 : this.closeModal}
            showButton2={this.state.showButton2}
            button2Text={this.state.buttonText2}
            button2Press={this.state.onButtonClick2 ? this.state.onButtonClick2 : this.closeModal}
            // icon={this.state.modalIcon}
            buttonTextStyle={{ fontSize: 15 }}
            // showCloseIcon={this.state.showCloseIcon}
            // closeIconPress={this.closeModal}
            onHide={this.closeModal}
          /> */}
        </IonContent>
      </IonPage>
    ) : (
      <IonPage>
        {this.props.data.selectedOrigin &&
          this.props.data.selectedDestination ? (
          <BusLoader
            from={this.props.data.selectedDestination.name}
            to={this.props.data.selectedOrigin.name}
            travelDate={this.props.data.selectedReturnDate}
          />
        ) : null}
      </IonPage>
    );
  }
}

const styles = {
  serviceListContainer: {
    flex: 1,
  },
};

export default connectData()(withIonLifeCycle(ServiceListReturnScreenV2));
