import React, { PureComponent } from "react";

import PropTypes from 'prop-types';

class PButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    secondary: PropTypes.bool,
    onPress: PropTypes.func,
    style: PropTypes.object,
    button1FontColor: PropTypes.string,
    title: PropTypes.string,
    capitalize: PropTypes.bool,
    bold: PropTypes.bool,

  }

  constructor(props) {
    super(props)
  }

  render() {
    const operator = JSON.parse(localStorage.getItem('op'))
    return (
      <button
        className={"pb-button " + this.props.className + (this.props.secondary ? ' secondary-button' : '')}
        onClick={this.props.onClick ? this.props.onClick : this.props.onPress}
        style={this.props.style, { backgroundColor: operator && operator.button_color ? operator.button_color : '',borderRadius: operator && operator.theme_type == 1 ? 50 : ''  }}

        type={this.props.type || 'button'}
      >
        <div
          className={"pb-button-text-container " + this.props.button1FontColor + " " + (this.props.bold ? 'bold-text' : '')
          }
        >
          {
            this.props.title
              ? <span style={this.props.buttonSize}>
                {this.props.capitalize ? this.props.title.toUpperCase() : this.props.title}
              </span>
              : this.props.children
          }
        </div>
      </button>
    )
  }

}

export default PButton;