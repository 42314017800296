import React from 'react'
import { Route } from 'react-router-dom';

const SaoPauloTermsAndConditions = React.lazy(() => import("../theme5/SaoPauloPages/SaoPauloTermsAndConditions"));
const saoPauloAboutUs = React.lazy(() => import("../theme5/SaoPauloPages/saoPauloAboutUs"));
const SaoPauloOffices = React.lazy(() => import("../theme5/SaoPauloPages/SaoPauloOffices"));
const SaoPauloDestinationScreen = React.lazy(() => import("../theme5/SaoPauloPages/SaoPauloDestinationScreen"));
const SaoPauloContactUs = React.lazy(() => import("../theme5/SaoPauloPages/SaoPauloContactUs"));



const saoPauloRoutes = [
    <Route exact path="/saopaulo-destinos" component={SaoPauloDestinationScreen} />,
    <Route exact path="/about-saopaulo" component={saoPauloAboutUs} />,
    <Route exact path="/saopaulo-terms" component={SaoPauloTermsAndConditions} />,
    <Route exact path="/saopaulo-office" component={SaoPauloOffices} />,
    <Route exact path="/contact" component={SaoPauloContactUs} />

]


export default saoPauloRoutes
