import React from 'react'
import { Route } from 'react-router-dom';

const AndimarTermsAndConditions = React.lazy(() => import("../theme1/AndimarPages/AndimarTermsAndConditions"));
const AndimarOffices = React.lazy(() => import("../theme1/AndimarPages/AndimarOffices"));
const AndimarFleet = React.lazy(() => import("../theme1/AndimarPages/FleetScreen"));
const AndimarDestinations = React.lazy(() => import("../theme1/AndimarPages/AndimarDestinations"));
const AndimarContactUs = React.lazy(() => import("../theme1/AndimarPages/AndimarContactUs"));


const andimarRoutes = [
    <Route exact path="/destinos" component={AndimarDestinations} />,
    <Route exact path="/fleet" component={AndimarFleet} />,
    <Route exact path="/terms" component={AndimarTermsAndConditions} />,
    <Route exact path="/office" component={AndimarOffices} />,
    // <Route exact path="/gallery" component={TepualGallery} />,
    <Route exact path="/contact" component={AndimarContactUs} />
]


export default andimarRoutes
