import React from 'react'
import commonStyles from '../../theme/commonStyles';
import DateService from '../../services/dateService';
import PBText from '../PBText';
import PBTouchable from '../PBTouchable';
import { IonIcon } from "@ionic/react";



const TravelDetailsHeader = props => {
    return (
      <div
        className="travel-details-header-container"
        // style={styles.travelDetailsHeaderContainer}
      >
        <div className="font14 text-center">
            {props.from} - {props.to}
        </div>
        <div
          className="date-details-container flex-row"
        >
            <div 
            onClick={() => props.onDayChange(1)}
             className="date-arrow">
              <img
                className="date-arrow"
                src="../assets/svgs/left-arrow.svg"
                color={
                  DateService.getToday() == props.travelDate
                    ? "#fff"
                    : "#fa8039"
                }
              />
            </div>
          <div
            className="travel-date text-center font14 light-font"
            // style={styles.travelDate}
          >
              {props.travelDate
                ? DateService.getServiceHeaderDateFormat(
                    props.travelDate
                  )
                : ""}
          </div>
            <div
              className="date-arrow"
              // styles={styles.dateArrow}
            onClick={() => props.onDayChange(2)}
            >
              <img
                src="../assets/svgs/right-arrow.svg"
                size={24}
                color="#fa8039"
              />
            </div>
        </div>
      </div>
    );
  };


const styles = {
    fromTo:{
      textAlign:'center'
    },
    travelDetailsHeaderContainer:{
        alignItems:'center',
        justifyContent:'center',
        padding:8,
        backgroundColor:'#fff'
      },
      dateArrow:{
        padding:15,
        paddingHorizontal:20
      },
      travelDate:{
        marginLeft:15,
        marginRight:15
      },
}


export default TravelDetailsHeader;