import React, { PureComponent } from "react";

import PropTypes from "prop-types";
import PBText from "./PBText";
import PBModal from "./PBModal";

class PoolModal extends PureComponent {
  static propTypes = {
    showPoolModal: PropTypes.bool,
    onBackButtonPress: PropTypes.func,
  };

  constructor(props) {
    super(props);
  }

  renderThreeItems = (icon, title, desc) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 20,
          alignItems: "center",
        }}
      >
        {icon && <img src={icon} style={{ height: 60, width: "auto" }} />}
        <div className="modal-desc-container">
          <PBText bold={true} primary={true} style={{ fontSize: 12 }}>
            {title}
          </PBText>
          <PBText style={{ fontSize: 12 }}>{desc}</PBText>
        </div>
      </div>
    );
  };

  render() {
    // console.log("Props on pool modal", this.props);
    return (
      <PBModal
        showModal={this.props.showPoolModal}
        type="alert"
        // icon={"/assets/carpool/cancel-ticket.svg"}
        onBackButtonPress={() => this.props.onBackButtonPress()}
        bottom={this.props.bottom}
      >
        <div
          style={{
            padding: 10,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <PBText
            primary={true}
            bold={true}
            center={true}
            style={{ fontSize: 15 }}
          >
            Comparte tus viajes
            <PBText
              primary={true}
              bold={true}
              center={true}
              style={{ fontSize: 15 }}
            >
              y costos con
            </PBText>
          </PBText>
          <img className="modal-top-img" src="/assets/carpool/kupospool.png" />

          <div className="modal-item-conatiner">
            {this.renderThreeItems(
              "/assets/carpool/popup/pop-up-travelsafe-icon.svg",
              "Viaja seguro",
              "Viajes asegurados, usuarios verificados e información protegida."
            )}
            {this.renderThreeItems(
              "/assets/carpool/popup/pop-up-bestprice-icon.svg",
              "Viaja más económico",
              "El mejor precio para tu viaje, sin cargos adicionales."
            )}
            {this.renderThreeItems(
              "/assets/carpool/popup/pop-up-eco-friendly-icon.svg",
              "Eco-friendly",
              "Viaja compartido con menos emisiones de carbono."
            )}
          </div>

          <div className="modal-download-desc-container">
            <PBText center={true}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <PBText bold={true}>KuposPool </PBText> &nbsp;sólo está
              </div>
              <PBText>disponible en nuestra app</PBText>
              <PBText bold={true} primary={true}>
                ¡Descárgala gratis!
              </PBText>
            </PBText>
          </div>

          <div
            // style={{ position: "absolute", bottom: 0, left: 20 }}
            className="modal-bottom-img-container"
          >
            <div className="modal-social-icon-container">
              <a
                href={
                  this.props.data &&
                  this.props.data.metaData &&
                  this.props.data.metaData.social_media_urls.androidUrl
                }
              >
                <img
                  className="modal-socail-img"
                  src="/assets/carpool/popup/pop-up-googleplay-logo-es.svg"
                />
              </a>

              <a
                href={
                  this.props.data &&
                  this.props.data.metaData &&
                  this.props.data.metaData.social_media_urls.iosUrl
                }
              >
                <img
                  className="modal-socail-img"
                  src="/assets/carpool/popup/pop-up-appstore-logo-es.svg"
                />
              </a>
            </div>

            <img
              className="modal-bottom-img"
              src="/assets/carpool/popup/kupospool-pop-up-wave.svg"
            />
          </div>
        </div>
      </PBModal>
    );
  }
}

export default PoolModal;
