import React from 'react'
import { Route } from 'react-router-dom';

const ViaturContactUs = React.lazy(() => import("../theme3/ViaturPages/ViaturContactUs"));


const viaturRoutes = [
    <Route exact path="/contact" component={ViaturContactUs} />

]


export default viaturRoutes
