import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import {
//     div,
//     Platform
// } from 'react-native';

// import EStyleSheet from 'react-native-extended-stylesheet';
// import { Calendar, CalendarList, Agenda } from 'react-native-calendars';
// import Modal from "react-native-modal";
// import Image from 'react-native-scalable-image';
import {IonIcon} from '@ionic/react';

import PBCard from './PBCard';
import PBText from './PBText';
import colors from '../theme/colors';
import PBTouchable from './PBTouchable';
import PBSafeView from './PBSafeView';
import { dimensions } from '../theme';



// header: {
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     paddingLeft: 0,
//     paddingRight: 0,
//     marginTop: 0,
//     marginHorizontal:0,
//     alignItems: 'center',
//     backgroundColor: appStyle.headerBackgroundColor,
//     borderTopRightRadius: appStyle.borderRadius,
//     borderTopLeftRadius: appStyle.borderRadius
//   },

const calendarTheme = {
    backgroundColor: colors.primaryBG,
    calendarBackground: '#ffffff',
    textSectionTitleColor: '#f58234',
    selectedDayBackgroundColor: '#FDE8E0',
    selectedDayTextColor: '#f58234',
    todayTextColor: '#00adf5',
    // todayBackgroundColor:'#ccc',
    headerBackgroundColor: '#f58234',
    borderRadius:5,
    headerBorderRadius:10,
    dayTextColor: '#999',
    // textDisabledColor: '#d9e1e8',
    // dotColor: '#00adf5',
    // selectedDotColor: '#ffffff',
    // arrowColor: 'orange',
    monthTextColor: colors.primaryText,
    indicatorColor: colors.primaryBG,
    textDayFontFamily: 'Carnas Regular',
    textMonthFontFamily:  'Carnas Regular',
    textDayHeaderFontFamily:  'Carnas Regular',
    // textDayFontWeight: '300',
    // textMonthFontWeight: 'bold',
    // textDayHeaderFontWeight: '300',
    // textDayFontSize: 16,
    // textMonthFontSize: 16,
    // textDayHeaderFontSize: 16
}

const Arrow = ({arrowName}) => {
    return (
        arrowName == 'left' 
        ?<div style={styles.calArrow}><IonIcon style={styles.showPasswordIcon} name={'ios-arrow-back'} size={18} color={colors.primaryText} /></div>
        :<div style={styles.calArrow}><IonIcon style={styles.showPasswordIcon} name={'ios-arrow-forward'} size={18} color={colors.primaryText} /></div>
    )
}


class CarpoolCalendar  extends PureComponent{
    state = {
        showCalendar:true
    }
    constructor(props){
        super(props)
    }
    render(){
        let markDate = {};
        markDate[this.props.selectedDate] = { selected: true, selectedColor: 'orange' }
        return (
            <div
                style={{ margin: 0 }}
                isVisible={this.props.showCalendar}
                onBackButtonPress={this.props.onBackButtonPress ? this.props.onBackButtonPress : () => {} }
                onBackdropPress={this.props.onBackdropPress ? this.props.onBackdropPress : () => {} }
                animationTime={500}
                >
                        <PBCard padding={20} style={{width:dimensions.vw*100-40,marginHorizontal:20,borderRadius:15}}>
                            <div style={{padding:10,paddingBottom:30}}>
                                <PBText bold={true} dark={true} style={{textAlign:'center'}}>Selecciona tu fecha de viaje</PBText>
                            </div>
                            {/* <Calendar
                                // Handler which gets executed on day press. Default = undefined
                                onDayPress={(day) => { this.props.onDateChange(day) }}
                                // Handler which gets executed when press arrow icon left. It receive a callback can go back month
                                onPressArrowLeft={substractMonth => substractMonth()}
                                // Handler which gets executed when press arrow icon left. It receive a callback can go next month
                                onPressArrowRight={addMonth => addMonth()}
                                current = {this.props.selectedDate}
                                monthUpperCase = {true}
                                markedDates={markDate}
                                minDate={this.props.minDate}
                                style={{
                                    borderRadius: 5
                                }}
                                theme={calendarTheme}
                                renderArrow={(direction) => (<Arrow arrowName={direction}/>)}
                            /> */}
                            
                        </PBCard>
            </div>
        );
    }
}


const styles = {

    calendarContainer: {
        flex: 1,
        backgroundColor: '$pageBG',
    },
    calendarTopBar: {
        flexDirection:'row',
        backgroundColor: '$primaryBG',
        width:'100%',
        height:54,
        alignItems:'center'
    },
    calendarTopBarIconContainer:{
        padding:15,
        marginLeft:10,
        zIndex:9
    },
    calendarTopBarTextContainer:{
        width:'100%',
        alignItems:'center',
        justifyContent:'center',
        position:'absolute',
        zIndex:1
    },
    calendarTopBarText:{
        color:'#fff'
    },
    clearDate:{
        position:'absolute',
        right: 0,
        top:0,
        height:54,
        paddingHorizontal:20,
        zIndex:9,
        justifyContent:'center'
    },
    clearText:{
        color:'#fff'
    },
    calArrow:{
        paddingHorizontal:10
    }
}


export default CarpoolCalendar;