import React from "react";
// import {
//     div,
//     FlatList,
// } from 'react-native';
// import EStyleSheet from 'react-native-extended-stylesheet';
import ServiceListItem from "./ServiceListItem";
import PBText from "../PBText";
import { IonList } from "@ionic/react";
import { CommonService } from "../../services";

const ServiceListItemContainer = (props) => {
  // console.log("ServiceListItemContainer", props);
  let allRoutes = props.routes ? props.routes.concat(props.soldRoutes) : [];
  // let renderItem =
  //         ({ item }) => (
  //           <ServiceListItem
  //             service={item}
  //             amenities={props.amenities}
  //             onSerivceClicked={() => props.onServiceSelect(item)}
  //           />
  //         );

  // console.log("renderItems", renderItem);
  // let operatorId = CommonService.getParameterByName("operator_id");

  const operator = JSON.parse(localStorage.getItem("op"));
  const operatorId = CommonService.getParameterByName('operator_id') || (operator && operator.operator_id);

  // console.log("Operator id-----", this.operatorId);
  return (
    <div className="list-item-container">
      {props.routes && props.routes.length ? (
        <div
        // data={allRoutes}
        // showsVerticalScrollIndicator={false}
        // keyExtractor={item => item.id + ""}
        >
          {allRoutes.map((item, key) => {
            // console.log("Item is outside ------", item);
            if (operatorId) {
              if (operatorId == item.travel_id) {
                // console.log("Item is ------", item);
                return (
                  <ServiceListItem
                    key={key}
                    service={item}
                    amenities={props.amenities}
                    onSerivceClicked={() => props.onServiceSelect(item)}
                    operator={props.operator}

                  />
                );
              }
            } else {
              return (
                <ServiceListItem
                  key={key}
                  service={item}
                  amenities={props.amenities}
                  onSerivceClicked={() => props.onServiceSelect(item)}
                  operator={props.operator}

                />
              );
            }
          })}

          {/* {renderItem} */}
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
          }}
        >
          {/* <PBText style={{ textAlign: "center" }}>
            ¡Ups! Actualmente no tenemos este servicio disponible, pero estamos
            trabajando para integrarlo. Por favor intenta explorando otros
            destinos.
          </PBText> */}

          <PBText style={{ textAlign: "center" }}>
            Este servicio no está disponible en la fecha que seleccionaste. Por
            favor intenta con otra fecha.
          </PBText>
        </div>
      )}
    </div>
  );
};

const styles = {
  serviceListItemContainer: {
    width: "100%",
    flex: 1,
  },
};

export default ServiceListItemContainer;
