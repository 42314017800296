import React from "react";

// import Icon from 'react-native-vector-icons/AntDesign';

import { CommonService, DateSerivce } from "../../services";
import Rating from "./Rating";

import { commonStyles, dimensions } from "../../theme";
import PBText from "../PBText";
import PBTouchable from "../PBTouchable";
import { IonIcon } from "@ionic/react";
import DateService from "../../services/dateService";

const getAmenitiesImage = (name) => {
  switch (name) {
    case "air_condtion.png": {
      return "../assets/svgs/amenities/air_condtion.svg";
    }
    case "baggage.png": {
      return "../assets/svgs/amenities/baggage.svg";
    }
    case "charging_plug.png": {
      return "../assets/svgs/amenities/charging_plug.svg";
    }
    case "coffee.png": {
      return "../assets/svgs/amenities/coffee.svg";
    }
    case "food_new_icon.png": {
      return "../assets/svgs/amenities/food_new_icon.svg";
    }
    case "gaming.png": {
      return "../assets/svgs/amenities/gaming.svg";
    }
    case "handicap.png": {
      return "../assets/svgs/amenities/handicap.svg";
    }
    case "mobile_ticket.png": {
      return "../assets/svgs/amenities/mobile_ticket.svg";
    }
    case "movie.png": {
      return "../assets/svgs/amenities/movie.svg";
    }
    case "restrooms.png": {
      return "../assets/svgs/amenities/restrooms.svg";
    }
    case "snacks_new.png": {
      return "../assets/svgs/amenities/snacks_new.svg";
    }
    case "wifi.png": {
      return "../assets/svgs/amenities/wifi.svg";
    }
    default: {
      return "";
    }
  }
};

const getSeatName = (rawSeat) => {
  console.log("Raw seat".rawSeat);
  switch (rawSeat) {
    case "SALON CAMA":
      return "Salón Cama";
      break;
    case "CLASICO":
      return "Clásico";
      break;
    case "Salon Sin Vent":
      return "Salón sin vent";
      break;
    case "Salon Preferente":
      return "Salón Preferente";
      break;
    case "SALON PREFERENTE":
      return "Salón Preferente";
      break;
    case "SALON":
      return "Salón ";
      break;
    case "Salon":
      return "Salón ";
      break;
    default:
      return CommonService.capitalize(rawSeat);
      break;
  }
};

const ServiceListItem = (props) => {
  let service = props.service;
  let color = service.soldRoute ? { color: "#ccc" } : {};
  let seatTypes = () => {
    // console.log("hello ", service.seatType);
    let seatTypes = service.seatType.map((type, i) => (
      <div
        className={"seat-type-container "}
        // style={styles.seatTypeContainer}
        key={i}
      >
        <span className="font12 dark-font dark-font">
          {getSeatName(type.split(":")[0])}
        </span>
        <span
          className={
            "font12  bold-text " +
            (service.soldRoute ? "sold-service dark-font" : "secondary-font")
          }
          style={{ color: props.operator && props.operator.button_color ? `${props.operator.button_color} !important` : '' }}

        >
          {CommonService.currency(type.split(":")[1]).split(",")[0]}
        </span>
      </div>
    ));
    return seatTypes;
  };

  let amenities = () => {
    let amenities = service.operator_details[3].map((am, i) =>
      i < 3 ? (
        <img
          key={i}
          className="amenity"
          // style={styles.amenity}
          height={15}
          src={getAmenitiesImage(props.amenities[am].toLowerCase())}
        />
      ) : null
    );
    return amenities;
  };

  return (
    <div>
      <div
        className="item-container-outer kupos-card"
        onClick={props.onSerivceClicked}
      // style={styles.itemContainerOuter}
      >
        <div
          className="item-container "
        // style={styles.itemContainer}
        >
          <div className="op-name-row flex-row">
            <img
              className={
                "operator-image" + (service.soldRoute ? " sold-service" : "")
              }
              src={service.operator_details[0]}
            />
            <div
              className={
                "font12 on-name" + (service.soldRoute ? " sold-service" : "")
              }
            // style={{ position: "absolute", top: "10", left: "100", ...color }}
            >
              {service.operator_service_name.toUpperCase()}
            </div>
            <div
              className={
                "rating black-text dark-font font12" +
                (service.soldRoute ? " sold-service" : "")
              }
            >
              {service.operator_details[1].toFixed(1)}
              <img src="../assets/icons/Icon-star-full-orange.png" />
            </div>
          </div>
          <div className="service-details-row flex-row">
            <div className="amenities-date">
              <div className="time-row flex-row">
                <div className="time-info black-text dark-font">
                  <span
                    className={
                      "time font14" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {service.dep_time.lastIndexOf(":") > 4
                      ? service.dep_time.substr(0, 5)
                      : service.dep_time}
                  </span>
                  <span
                    className={
                      "ampm font7" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {DateService.ampmOnly(
                      service.dep_time.lastIndexOf(":") > 4
                        ? service.dep_time.substr(0, 5)
                        : service.dep_time
                    )}
                  </span>
                </div>
                <div
                  className={
                    "time-seperator font10 light-text" +
                    (service.soldRoute ? " sold-service" : "")
                  }
                >
                  <span className={service.soldRoute ? " sold-service" : ""}>
                    {service.duration} horas
                  </span>
                  <img src="/assets/svgs/two-ways-arrow.svg" />
                  {/* <div className="border-bottom-item-list"></div> */}
                </div>

                {/* <div className="duration-hours">
                <span className="duration">{service.duration}</span>
                <img src="../assets/svgs/two-ways-arrow.svg" />
                <span className="hours">Horas</span>
              </div> */}
                <div
                  className={
                    "time-info black-text dark-font" +
                    (service.soldRoute ? " sold-service" : "")
                  }
                >
                  <span
                    className={
                      "time font14" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {service.arr_time.lastIndexOf(":") > 4
                      ? service.arr_time.substr(0, 5)
                      : service.arr_time}
                  </span>
                  <span
                    className={
                      "ampm font7" + (service.soldRoute ? " sold-service" : "")
                    }
                  >
                    {DateService.ampmOnly(
                      service.arr_time.lastIndexOf(":") > 4
                        ? service.arr_time.substr(0, 5)
                        : service.arr_time
                    )}
                  </span>
                </div>
              </div>

              <div
                className={
                  "date-row flex-row font11 light-text space-between  light-text" +
                  (service.soldRoute ? " sold-service" : "")
                }
              >
                <span className="">
                  {DateService.getServiceItemDate(service.travel_date)}
                </span>
                <span className="">
                  {DateService.getServiceItemDate(service.arrival_date)}
                </span>
              </div>
              <div
                className="view-flex amenities-rating-container"
              // style={{ ...styles.viewFlex, paddingTop: 4 }}
              >
                <div
                  className="amenities-container"
                // style={styles.amenitiesContainer}
                >
                  {amenities()}
                </div>
              </div>
            </div>
            <div
              className={
                "view-flex seat-type-container-inner " +
                (service.soldRoute ? "sold-service" : "")
              }
            // style={{ ...styles.viewFlex, ...styles.marginTop }}
            >
              {/* <PBText style={{...styles.opName,...styles.hidden}}>J</PBText> */}
              {seatTypes()}
              {service.soldRoute ? (
                <div
                  className={
                    "sold-out-text " + (service.soldRoute ? "sold-service" : "")
                  }
                // style={styles.soldOutText}
                >
                  <span
                    className={"font12"}
                  // style={{ ...color, ...styles.textMarginTop }}
                  >
                    Agotado
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {service.offer_text && (
        <div className="offer-text font11">
          <span className="offer-percent font13 bold-text">%</span>
          <span>
            {service.offer_text.split("[[")[0]}{" "}
            {service.offer_text.split("[[")[1] ? (
              <span className="bold-text">
                {" "}
                {`"${service.offer_text.split("[[")[1]}"`}
              </span>
            ) : null}{" "}
            {service.offer_text.split("[[")[2]}
          </span>
        </div>
      )}
    </div>
  );
};

const styles = {
  itemContainerOuter: {
    width: "100%",
    display: "flex",
  },
  itemContainer: {
    width: "100%",
    flexDirection: "row",
    backgroundColor: "#fff",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  viewFlex: {
    width: dimensions.vw * 31,
    padding: 5,
    paddingVertical: 10,
    alignItems: "center",
  },
  arrow: {
    padding: 3,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  amenitiesContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    marginTop: 3,
    paddingLeft: 7,
  },
  amenity: {
    marginRight: 8,
  },
  serviceDetails: {
    alignItems: "flex-start",
    marginTop: 321,
  },
  opName: {
    fontSize: 12,
    position: "absolute",
    top: 9,
    left: 100,
  },
  marginTop: {
    marginTop: 16,
  },
  time: {
    fontSize: 10,
  },
  duration: {
    fontSize: 10,
  },
  hidden: {
    color: "#fff",
  },
  textMarginTop: {},
  seatTypeContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  seatType: {
    fontSize: 10,
  },
  seatFare: {
    fontSize: 10,
  },
  soldOutText: {
    width: "100%",
    alignItems: "flex-start",
    fontSize: 12,
  },
};

export default ServiceListItem;
