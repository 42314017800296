/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
// import 'react-image-crop/dist/ReactCrop.css';
import './css/global.css';
/* Theme variables */
import './theme/variables.css';

import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { Loader, PBContactModal } from './components';
import React, { Suspense } from 'react';

import { AppPage } from './declarations';
import { CommonService } from './services';
import { IonReactRouter } from '@ionic/react-router';
import KuposHelmet from './themes/common/KuposHelmet';
import Menu from './components/Menu';
import { Provider } from './redux';
import { Route } from 'react-router-dom';
import ServiceDetailsReturnScreenV2 from './screens/ServiceDetailsReturnScreen/ServiceDetailsReturnScreenV2';
import ServiceDetailsScreenV2 from './screens/ServiceDetailsScreen/ServiceDetailsScreenV2';
import ServiceListReturnScreenV2 from './screens/ServiceListReturnScreen/ServiceListReturnScreenV2';
import ServiceListScreenV2 from './screens/ServiceListScreen/ServiceListScreenV2';
import getRoutes from './themes/routes/getRoutes';
import getSideMenuLinks from './themes/functions/getSideMenuLinks';

const CarpoolTermsOfUseScreen = React.lazy(
	() => import('./screens/CarpoolTermsOfUseScreen/CarpoolTermsOfUseScreen')
);
const HomeScreen = React.lazy(() => import('./screens/HomeScreen/HomeScreen'));
// const ServiceListScreen = React.lazy(() => import("./screens/ServiceListScreen/ServiceListScreen"));
// const ServiceDetailsScreen = React.lazy(() => import("./screens/ServiceDetailsScreen/ServiceDetailsScreen"));
const PassengerDetailsScreen = React.lazy(
	() => import('./screens/PassengerDetailsScreen/PassengerDetailsScreen')
);
const PaymentDetailsScreen = React.lazy(
	() => import('./screens/PaymentDetailsScreen/PaymentDetailsScreen')
);
const ThankyouScreen = React.lazy(
	() => import('./screens/ThankyouScreen/ThankyouScreen')
);
// const ServiceListReturnScreen = React.lazy(() => import("./screens/ServiceListReturnScreen/ServiceListReturnScreen"));
const CancellationScreen = React.lazy(
	() => import('./screens/CancellationScreen/CancellationScreen')
);
const CancellationDetailsScreen = React.lazy(
	() =>
		import(
			'./screens/CancellationDetailsScreen/CancellationDetailsScreen'
		)
);
const CancelSuccessScreen = React.lazy(
	() => import('./screens/CancelSuccessScreen/CancelSuccessScreen')
);
// const ServiceDetailsReturnScreen = React.lazy(() => import("./screens/ServiceDetailsReturnScreen/ServiceDetailsReturnScreen"));
const RefundFormScreen = React.lazy(
	() => import('./screens/RefundFormScreen/RefundFormScreen')
);

// Themes Screen
// const DestinationScreen = React.lazy(() => import("./themes/theme5/DestinationScreen"));

if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.register('/sw.js') //
		.then(function (reg) {
			console.log('service worker registered');
		})
		.catch(function (err) {
			console.log(err);
		});
}

const appPages2: AppPage[] = [
	{
		id: '4',
		name: 'Términos de uso',
		src: '../assets/icons/menu/icon_info.png',
		icon: 'icon_info',
		// "screen":TXN_HISTORY_SCREEN,
		title: 'Términos de uso',
		url: 'tnc',
	},

	// {
	//   id: "9",
	//   name: "Contáctanos",
	//   src: "../assets/icons/menu/icon_contact.png",
	//   icon: "icon_contact",
	//   // "screen":MY_TICKETS_SCREEN,
	//   title: "Contáctanos",
	//   url: "pasajes",
	// },
];

class App extends React.Component {
	state: any;
	getSideMenuLinks: any;
	constructor(props: any) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const customEvent = new Event('addToHomeButtonShowEvent');
		window.addEventListener('beforeinstallprompt', (event: any) => {
			// Prevent Chrome <= 67 from automatically showing the prompt
			event.preventDefault();
			// Stash the event so it can be triggered later.
			CommonService.addToHomeButtonInstallPrompt = event;
			window.dispatchEvent(customEvent);
			// Update the install UI to notify the user app can be installed
			// document.querySelector('#install-button').disabled = false;
			// setAddToHomeState(true)
		});
	}

	render() {
		return (
			<Provider>
				<IonApp>
					<KuposHelmet />
					<PBContactModal />
					<IonReactRouter>
						<IonSplitPane contentId="main">
							<Suspense fallback={<Loader />}>
								<Menu appPages={getSideMenuLinks()} />
								<IonRouterOutlet id="main">
									<Route
										exact
										path="/"
										component={HomeScreen}
									/>

									{/* -------------------------------- es routes for bus started -------------------------------- */}
									<Route
										exact
										path="/es/pasajes-bus"
										component={HomeScreen}
									/>

									<Route
										exact
										path="/es/pasajes-bus/:origin/:dest/:dateOnward"
										component={
											ServiceListScreenV2
										}
									/>
									<Route
										exact
										path="/es/pasajes-bus/:origin/:dest/:dateOnward/:dateReturn/1"
										component={
											ServiceListScreenV2
										}
									/>
									<Route
										exact
										path="/es/pasajes-bus/:origin/:dest/:dateOnward/:dateReturn/2"
										component={
											ServiceListReturnScreenV2
										}
									/>
									<Route
										exact
										path="/es/pasajes-bus/servicedetails"
										component={
											ServiceDetailsScreenV2
										}
									/>
									<Route
										exact
										path="/es/pasajes-bus/select-seat/:scheduleId/:origin/:dest/:dateOnward"
										component={
											ServiceDetailsScreenV2
										}
									/>
									<Route
										exact
										path="/es/pasajes-bus/select-seat/:scheduleId/:origin/:dest/:dateOnward/:dateReturn/:step"
										component={
											ServiceDetailsScreenV2
										}
									/>
									<Route
										exact
										path="/es/pasajes-bus/select-seat/:scheduleId/:scheduleIdReturn/:origin/:dest/:dateOnward/:dateReturn/:step"
										component={
											ServiceDetailsReturnScreenV2
										}
									/>

									<Route
										exact
										path="/es/pasajes-bus/passenger-details"
										component={
											PassengerDetailsScreen
										}
									/>

									<Route
										exact
										path="/es/pasajes-bus/servicedetails/passenger-list-return-screen"
										component={
											ServiceListReturnScreenV2
										}
									/>
									<Route
										exact
										path="/es/pasajes-bus/payment-details"
										component={
											PaymentDetailsScreen
										}
									/>

									<Route
										exact
										path="/es/booking/:status/:pnrNumber"
										component={ThankyouScreen}
									/>

									{/* -------------------------------- es routes for bus ended -------------------------------- */}

									{/* -------------------------------- en routes for bus started -------------------------------- */}

									<Route
										exact
										path="/en/bus-tickets"
										component={HomeScreen}
									/>
									<Route
										exact
										path="/en/bus-tickets/:origin/:dest/:dateOnward"
										component={
											ServiceListScreenV2
										}
									/>
									<Route
										exact
										path="/en/bus-tickets/:origin/:dest/:dateOnward/:dateReturn/1"
										component={
											ServiceListScreenV2
										}
									/>
									<Route
										exact
										path="/en/bus-tickets/:origin/:dest/:dateOnward/:dateReturn/2"
										component={
											ServiceListReturnScreenV2
										}
									/>
									<Route
										exact
										path="/en/bus-tickets/servicedetails"
										component={
											ServiceDetailsScreenV2
										}
									/>
									<Route
										exact
										path="/en/bus-tickets/select-seat/:scheduleId/:origin/:dest/:dateOnward"
										component={
											ServiceDetailsScreenV2
										}
									/>
									<Route
										exact
										path="/en/bus-tickets/select-seat/:scheduleId/:origin/:dest/:dateOnward/:dateReturn/:step"
										component={
											ServiceDetailsScreenV2
										}
									/>
									<Route
										exact
										path="/en/bus-tickets/select-seat/:scheduleId/:scheduleIdReturn/:origin/:dest/:dateOnward/:dateReturn/:step"
										component={
											ServiceDetailsReturnScreenV2
										}
									/>

									<Route
										exact
										path="/en/bus-tickets/passenger-details"
										component={
											PassengerDetailsScreen
										}
									/>

									<Route
										exact
										path="/en/bus-tickets/servicedetails/passenger-list-return-screen"
										component={
											ServiceListReturnScreenV2
										}
									/>
									<Route
										exact
										path="/en/bus-tickets/payment-details"
										component={
											PaymentDetailsScreen
										}
									/>

									<Route
										exact
										path="/en/booking/:status/:pnrNumber"
										component={ThankyouScreen}
									/>

									<Route
										exact
										path="/booking/:status/:pnrNumber"
										component={ThankyouScreen}
									/>

									{/* -------------------------------- en routes for bus ended -------------------------------- */}

									<Route
										exact
										path="/bus-cancel-trip"
										component={CancellationScreen}
									/>
									<Route
										exact
										path="/bus-cancel-trip/:pnrNumber"
										component={
											CancellationDetailsScreen
										}
									/>
									<Route
										exact
										path="/bus-cancel-trip/refund/:refunType"
										component={RefundFormScreen}
									/>

									<Route
										exact
										path="/bus-cancel-trip/refund-status/:type"
										component={
											CancelSuccessScreen
										}
									/>
									{/* <Route exact path="/bus-cancel-trip/:pnrNumber/:refundDetails" component={CancelSuccessScreen} /> */}

									{/* <Route exact path="/tnc" component={CarpoolTermsOfUseScreen} /> */}

									{getRoutes()}
								</IonRouterOutlet>
							</Suspense>
						</IonSplitPane>
					</IonReactRouter>
					<Loader />
				</IonApp>
			</Provider>
		);
	}
}
export default App;
