let headerLinks = {
    // Themes 5
    tepual: [
        { label: 'Compra tu pasaje', url: '/' },
        { label: 'Destinos', url: '/destinos' },
        { label: 'Nosotros', url: '/about-us' },
        { label: 'Términos y Condiciones', url: '/terms' },
        { label: 'Oficinas', url: '/office' },
        { label: 'Galeria', url: '/gallery' },
        { label: 'Contacto', url: '/contact' },
    ],

    saopaulo: [
        { label: 'Compra tu pasaje', url: '/' },
        { label: 'Destinos', url: '/saopaulo-destinos' },
        { label: 'La empresa', url: '/about-saopaulo' },
        { label: 'Oficinas', url: '/saopaulo-office' },
        { label: 'Términos y Condiciones', url: '/saopaulo-terms' },
        { label: 'Contacto', url: '/contact' },
    ],


    // Themes 3  Single page site

    viatur: [
        { label: 'Compra tu pasaje', url: '/' },
        { label: 'Contacto', url: '/contact' },
    ],

    kennybus: [
        { label: 'Compra tu pasaje', url: '/' },
        { label: 'Contacto', url: '/contact' },
    ]
}

const getSideMenuLinks = () => {
    const operator = JSON.parse(localStorage.getItem("op"));
    // const name = operator && operator.domain_url ? `${operator.domain_url.split('.')[0]}` : ''
    const name = operator && operator.operator_name ? `${operator.operator_name}` : ''

    let data = headerLinks[name]
    // console.log({ data }, 'data--------------------')
    return data ? data : []

}
export default getSideMenuLinks