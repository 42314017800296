import React, { PureComponent } from 'react'




class PBModalSliding extends PureComponent {

     constructor(props){
         super(props)
     }


    render() {
        return this.props.showModal ? (
          <div
            // backdropOpacity={this.props.opacity ? this.props.opacity : 0.5}
            // style={{ margin: 0 }}
            // isVisible={this.props.showModal}
            // onBackButtonPress={this.props.onBackButtonPress ? this.props.onBackButtonPress : () => {} }
            // onBackdropPress={this.props.onBackdropPress ? this.props.onBackdropPress : () => {} }
            // animationInTiming={this.props.animationTime || 300}
            // animationOutTiming={this.props.animationTime || 300}
            // backdropTransitionInTiming={this.props.animationTime || 300}
            // backdropTransitionOutTiming={this.props.animationTime || 300}
            // onSwipeComplete={this.props.onSwipeComplete ? this.props.onSwipeComplete : () => {} }
            // swipeDirection={this.props.swipeDirection}
            className="pb-modal-bottom"
            onClick={this.props.onBackdropPress}
          >
            {this.props.children}
          </div>
        ) : null;
    }
}



const styles = {
}

export default PBModalSliding;

