// may need to check the tepual icons again 

import tepualLocationIcon from '../assets/tepual/general/LocationIcon.svg'
import tepualPhoneIcon from '../assets/tepual/general/PhoneIcon.svg'
import tepualBG from '../assets/tepual/home/banner1.jpg'
import tepualOriginIcon from '../assets/tepual/home/SearchBar-OriginIcon.svg'
import tepualDestinationIcon from '../assets/tepual/home/SearchBar-DestinationIcon.svg'
import tepualCalendarIcon from '../assets/tepual/home/SearchBar-CalendarIcon.svg'
import tepualHeaderLogo from '../assets/tepual/general/Header-BusesTepualLogo.svg';
import tepualFooterLogo from '../assets/tepual/general/Footer-BusesTepualLogo.svg';

// Andimar Icons And Image 
import andimarDestinationBannerIcon from '../assets/andimar/destinations/destino_banner.jpg'
import andimarHeaderLogo from '../assets/andimar/destinations/destino_banner.jpg'
import andimarPhoneWhiteIcon from '../assets/andimar/contact-by/telephone-white.svg'
import andimarEmailWhiteIcon from '../assets/andimar/contact-by/email-white.svg'
import andimarOriginIcon from '../assets/andimar/contact-by/OriginIcon.svg'
import andimarDestinationIcon from '../assets/andimar/contact-by/DestinationIcon.svg'
import andimarDateIcon from '../assets/andimar/contact-by/DateIcon.svg'


// KennyBus assets
import kennybusHeaderLogo from '../assets/kennybus/general/kennybus-header-logo.png'
import kennybusFooterLogo from '../assets/kennybus/general/kennybus-footer-logo-white.png'
import kennybusBookingInActive from '../assets/kennybus/general/booking_white.svg'
import kennybusBookingActive from '../assets/kennybus/general/booking.svg'
import kennybusCancelInActive from '../assets/kennybus/general/cancel_white.svg'
import kennybusCancelActive from '../assets/kennybus/general/cancel.svg'

import kennybusWhatsapp from '../assets/kennybus/icons/whatsapp.svg'
import kennybusEmail from '../assets/kennybus/icons/email.svg'
import kennybusHeadPhone from '../assets/kennybus/icons/headphone.svg'
import kennybusYellowLine from '../assets/kennybus/icons/yellow-line.svg'
import kennybusBG from '../assets/kennybus/general/kenny_banner.png'


import kennybusOriginIcon from '../assets/kennybus/icons/origin.svg'
import kennybusDestinationIcon from '../assets/kennybus/icons/destination.svg'
import kennybusCalendarIcon from '../assets/kennybus/icons/calendar.svg'




import saopauloHeaderLogo from '../assets/saopaulo/general/header-logo.svg'
import saopauloBG from '../assets/saopaulo/home/home-banner.jpg'

import saopauloOriginIcon from '../assets/saopaulo/home/origin.svg'
import saopauloDestinationIcon from '../assets/saopaulo/home/destination.svg'
import saopauloCalendarIcon from '../assets/saopaulo/home/calendar.svg'


import footerKuposLogo from '../assets/common/Footer-kuposLogo.svg'



// Viatur 
import viaturBG from '../assets/viatur/home/viatur-banner.png'
import viaturOriginIcon from '../assets/viatur/home/origin.svg'
import viaturDestinationIcon from '../assets/viatur/home/destination.svg'
import viaturCalendarIcon from '../assets/viatur/home/calendar.svg'
import viaturHeaderLogo from '../assets/viatur/home/main-logo.svg'


// change andimar  name into your specific one you need 
const iconsArray = [
    // Andimar Start Here ...
    { icon: andimarDestinationBannerIcon, name: 'andimarDestinationBannerIcon' },
    { icon: andimarHeaderLogo, name: 'andimarheaderLogoIcon' },
    { icon: andimarPhoneWhiteIcon, name: 'andimarTelephoneIcon' },
    { icon: andimarEmailWhiteIcon, name: 'andimarEmailIcon' },
    { icon: andimarDateIcon, name: 'andimarCalendarIcon' },
    { icon: andimarOriginIcon, name: 'andimarOriginIcon' },
    { icon: andimarDestinationIcon, name: 'andimarDestinationIcon' },



    // tepual
    { icon: tepualPhoneIcon, name: 'andimarphoneIcon' },
    { icon: tepualLocationIcon, name: 'andimarlocationIcon' },
    { icon: tepualBG, name: 'tepualBGIcon' },
    { icon: tepualOriginIcon, name: 'tepualOriginIcon' },
    { icon: tepualDestinationIcon, name: 'tepualDestinationIcon' },
    { icon: tepualCalendarIcon, name: 'tepualCalendarIcon' },
    { icon: tepualHeaderLogo, name: 'tepualheaderLogoIcon' },
    { icon: tepualFooterLogo, name: 'tepualfooterLogoIcon' },




    // kennybus icons
    { icon: kennybusHeaderLogo, name: 'kennybusheaderLogoIcon' },
    { icon: kennybusFooterLogo, name: 'kennybusfooterLogoIcon' },

    { icon: kennybusBG, name: 'kennybusBGIcon' },

    { icon: kennybusCalendarIcon, name: 'kennybusCalendarIcon' },
    { icon: kennybusOriginIcon, name: 'kennybusOriginIcon' },
    { icon: kennybusDestinationIcon, name: 'kennybusDestinationIcon' },


    { icon: kennybusBookingInActive, name: 'kennybusBIAIcon' },
    { icon: kennybusBookingActive, name: 'kennybusBAIcon' },
    { icon: kennybusCancelInActive, name: 'kennybusCIAIcon' },
    { icon: kennybusCancelActive, name: 'kennybusCAIcon' },

    { icon: kennybusWhatsapp, name: 'kennybusWhatsappIcon' },
    { icon: kennybusEmail, name: 'kennybusEmailIcon' },
    { icon: kennybusHeadPhone, name: 'kennybusHeadphoneIcon' },
    { icon: kennybusYellowLine, name: 'kennybusLineIcon' },


    // ------------------
    { icon: saopauloHeaderLogo, name: 'saopauloheaderLogoIcon' },
    { icon: saopauloBG, name: 'saopauloBGIcon' },
    { icon: saopauloOriginIcon, name: 'saopauloOriginIcon' },
    { icon: saopauloDestinationIcon, name: 'saopauloDestinationIcon' },
    { icon: saopauloCalendarIcon, name: 'saopauloCalendarIcon' },


    // --------
    { icon: viaturHeaderLogo, name: 'viaturheaderLogoIcon' },
    { icon: viaturBG, name: 'viaturBGIcon' },
    { icon: viaturOriginIcon, name: 'viaturOriginIcon' },
    { icon: viaturDestinationIcon, name: 'viaturDestinationIcon' },
    { icon: viaturCalendarIcon, name: 'viaturCalendarIcon' },


]

const getIcons = (type, kuposLogo) => {
    const operator = JSON.parse(localStorage.getItem("op"));
    // const name = operator && operator.domain_url ? `${operator.domain_url.split('.')[0]}${type}Icon` : ''
    const name = operator && operator.operator_name ? `${operator.operator_name}${type}Icon` : ''

    if (kuposLogo) {
        return footerKuposLogo
    } else {
        const showIcon = iconsArray.find(val => val.name === name);
        return showIcon && showIcon.icon ? showIcon.icon : '';
    }
}

export default getIcons